import React, { useContext, useEffect, useRef, useState } from "react";
import "./invitation.scss";
import { useParams } from "react-router-dom";
import {
  CONFIRM_FORM,
  InvitationPresenter,
} from "./presenter/Invitation.presenter";
import {
  ArsaButton,
  ArsaFabButton,
  ArsaMap,
  LocalStorageEncryptService,
  UtilService,
  Context as ModalContext,
  useDeviceDetect,
  environment,
  LoadingService,
  HttpService,
  AlertService,
} from "arsaLocalv4";
import { useTranslation } from "react-i18next";
import moment from "moment";
import svg from "../../assets/imgs/invites/separators.svg";
import gift from "../../assets/imgs/invites/regagift.gif";

import newlyweds from "./../../assets/imgs/invites/newlyweds.png";

import wedding from "../../assets/imgs/invites/wedding.png";
import separator from "../../assets/imgs/invites/separator.png";
import flowers from "../../assets/imgs/invites/flowers.png";

import code1 from "../../assets/imgs/invites/code1.png";
import code2 from "../../assets/imgs/invites/code2.png";
import code3 from "../../assets/imgs/invites/code3.png";
import code4 from "../../assets/imgs/invites/code4.png";
import code5 from "../../assets/imgs/invites/code5.png";
import code6 from "../../assets/imgs/invites/code6.png";

import icon0 from "../../assets/imgs/invites/icon0.png";
import icon1 from "../../assets/imgs/invites/icon1.png";
import icon2 from "../../assets/imgs/invites/icon2.png";
import icon3 from "../../assets/imgs/invites/icon3.png";
import icon4 from "../../assets/imgs/invites/icon4.png";
import icon5 from "../../assets/imgs/invites/icon5.png";
import icon6 from "../../assets/imgs/invites/icon6.png";
import icon7 from "../../assets/imgs/invites/icon7.png";
import icon8 from "../../assets/imgs/invites/icon8.png";
import icon9 from "../../assets/imgs/invites/icon9.png";
import { ArsaForm } from "../../components/arsa-form/ArsaForm";

export function Invitation({}) {
  const { id } = useParams<any>();
  const { name } = useParams<any>();
  const [invite, setInvite] = useState<any>(null);
  const [phrase, setPhrase] = useState<any>(null);
  const [view, setView] = useState<any>(null);
  const [realMenu, setRealMenu] = useState<any>([]);
  const [pause, setPause] = useState<any>(true);
  const { t, i18n } = useTranslation();
  const modal = useContext<any>(ModalContext);
  const device: any = useDeviceDetect();

  const formRef = useRef(new Date().getTime());
  const [form, setForm] = useState(CONFIRM_FORM);

  useEffect(() => {
    init();
    try {
      setTimeout(() => {
        try {
          (
            document.getElementsByClassName("MuiDrawer-modal")[0] as any
          ).style.display = "none";
        } catch (error) {}
      }, 1000);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (invite) {
      try {
        (document.getElementById("menuApp") as any).style.display = "none";
      } catch (error) {}
      initUrls();

      let copyMenu: any = [];
      if (
        invite.json.principalForm &&
        invite.json.principalForm[0]?.value &&
        invite.json.principalForm[0]?.value?.length > 0
      ) {
        copyMenu.push({
          id: "top1",
          label: `${t("invite.menu1")}`,
        });
      }

      if (
        invite.json.principalForm &&
        invite.json.principalForm[4]?.value &&
        invite.json.principalForm[4]?.value?.length > 0
      ) {
        copyMenu.push({
          id: "top2",
          label: `${t("invite.menu2")}`,
        });
      }

      if (invite.json.formMaps && invite.json.formMaps[0]?.value) {
        copyMenu.push({
          id: "top3",
          label: `${t("invite.menu3")}`,
        });
      }

      if (
        invite.json.itineraryForm &&
        invite.json.itineraryForm[0] &&
        invite.json.itineraryForm[0][0]
      ) {
        copyMenu.push({
          id: "top4",
          label: `${t("invite.menu4")}`,
        });
      }

      if (
        invite.json.principalForm &&
        invite.json.principalForm[10]?.value &&
        invite.json.principalForm[10]?.value?.length > 0
      ) {
        copyMenu.push({
          id: "top5",
          label: `${t("invite.menu5")}`,
        });
      }

      if (
        invite.json.gifTable &&
        invite.json.gifTable[0] &&
        invite.json.gifTable[0][0]
      ) {
        copyMenu.push({
          id: "top6",
          label: `${t("invite.menu6")}`,
        });
      }

      if (invite.image_paths && invite.image_paths.length > 0) {
        copyMenu.push({
          id: "top7",
          label: `${t("invite.menu7")}`,
        });
      }
      setRealMenu(copyMenu);
    }
  }, [invite]);

  async function initUrls() {
    /* if (invite.url_sound) {
      setSound(
        await UtilService.getBase64(
          await UtilService.createFileFromUrl(invite.url_sound, "sound")
        )
      );
    } */
    var compareDate = new Date(invite?.json?.principalForm[4]?.value);

    var timer;
    timer = setInterval(() => {
      timeBetweenDates(compareDate, timer);
    }, 1000);
  }

  function init() {
    InvitationPresenter.getInvitation(
      id,
      name,
      setInvite,
      setPhrase,
      openInvite,
      t
    );
  }

  function openInvite() {
    modal.showModal({
      title: t("login.reset"),
      component: (
        <section className="welcome">
          <div>
            <p>{t("invite.welcome")}</p>
            <button
              onClick={() => {
                (document.querySelector(`#top1`) as any).scrollIntoView({
                  behavior: "smooth",
                });
                modal.closeModal();
                playSound();
              }}
            >
              {t("invite.openinv")}
            </button>
          </div>
        </section>
      ),
      footer: false,
      extraClassContainer: "welcomodal",
      isKeepOpen: true,
      continueAction: () => {},
      buttonText: "Continuar",
    });
  }

  function playSound() {
    let id: any = document.getElementById("soundtrack");

    if (!id.paused) {
      id.pause();
      setPause(true);
    } else {
      id.play();
      setPause(false);
    }
  }

  function timeBetweenDates(toDate, timer) {
    if (invite?.json?.principalForm[4]) {
      var dateEntered = toDate;
      var now = new Date();
      var difference = dateEntered.getTime() - now.getTime();

      if (difference <= 0) {
        // Timer done
        clearInterval(timer);
      } else {
        var seconds = Math.floor(difference / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);

        hours %= 24;
        minutes %= 60;
        seconds %= 60;

        (document.getElementById("days") as any).innerHTML = `${
          days < 10 ? "0" + days : days
        }`;
        (document.getElementById("hours") as any).innerHTML = `${
          hours < 10 ? "0" + hours : hours
        }`;
        (document.getElementById("minutes") as any).innerHTML = `${
          minutes < 10 ? "0" + minutes : minutes
        }`;
        (document.getElementById("seconds") as any).innerHTML = `${
          seconds < 10 ? "0" + seconds : seconds
        }`;
      }
    }
  }

  function openNav() {
    (document.getElementById("mySidenav") as any).style.height = "250px";
    (document.getElementById("mySidenav") as any).style.paddingTop = "20px";
  }

  function closeNav() {
    (document.getElementById("mySidenav") as any).style.height = "0";
    (document.getElementById("mySidenav") as any).style.paddingTop = "0px";
  }

  function returnCode(value) {
    switch (value) {
      case 1:
        return code1;
      case 2:
        return code2;
      case 3:
        return code3;
      case 4:
        return code4;
      case 5:
        return code5;
      case 6:
        return code6;
      default:
        return "";
    }
  }

  function returnIcon(value) {
    switch (value) {
      case 0:
        return icon0;
      case 1:
        return icon1;
      case 2:
        return icon2;
      case 3:
        return icon3;
      case 4:
        return icon4;
      case 5:
        return icon5;
      case 6:
        return icon6;
      case 7:
        return icon7;
      case 8:
        return icon8;
      case 9:
        return icon9;
      default:
        return undefined;
    }
  }

  return (
    <>
      {invite && (
        <section className="invitation">
          <section className="menu-web">
            {realMenu?.map((mnu: any) => {
              return (
                <div
                  onClick={() => {
                    (
                      document.querySelector(`#${mnu.id}`) as any
                    ).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {mnu.label}
                </div>
              );
            })}
          </section>

          <div id="mySidenav" className="sidenav">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={() => {
                closeNav();
              }}
            >
              &times;
            </a>

            {realMenu?.map((mnu: any) => {
              return (
                <a
                  onClick={() => {
                    (
                      document.querySelector(`#${mnu.id}`) as any
                    ).scrollIntoView({
                      behavior: "smooth",
                    });

                    closeNav();
                  }}
                >
                  {mnu.label}
                </a>
              );
            })}
          </div>

          {invite.url_img && (
            <div className="present-image">
              <div className="leimage">
                <img src={invite?.url_img} alt="presentation" />
              </div>
            </div>
          )}

          {invite.json.principalForm &&
            invite.json.principalForm[0]?.value &&
            invite.json.principalForm[0]?.value?.length > 0 && (
              <div className="principal-texts" id="top1">
                <div className="invite-type">
                  <img src={wedding} alt="" />
                </div>

                <section className="title-decorator">
                  <img src={separator} alt="" />
                  <p
                    style={{
                      fontFamily: invite?.json?.principalForm[1]?.value,
                    }}
                    className="msj"
                  >
                    {invite?.json.principalForm[0]?.value}
                  </p>
                  <img src={separator} alt="" />
                </section>

                <p
                  style={{
                    fontFamily: invite?.json?.principalForm[3]?.value,
                  }}
                  className="who"
                >
                  {invite?.json.principalForm[2]?.value}
                </p>
              </div>
            )}

          {invite.json.principalForm &&
            invite.json.principalForm[4]?.value &&
            invite.json.principalForm[4]?.value?.length > 0 && (
              <div
                className="when"
                id="top2"
                style={{
                  backgroundColor:
                    invite?.json?.principalForm[12]?.value ?? "#f7f7f7",
                }}
              >
                <p
                  style={{
                    fontFamily: invite?.json?.principalForm[9]?.value,
                  }}
                >
                  {(() => {
                    moment.locale("es");
                    let obj = {
                      esDay: [
                        "Lunes",
                        "Martes",
                        "Miércoles",
                        "Jueves",
                        "Viernes",
                        "Sábado",
                        "Domingo",
                      ],
                      enDay: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ],
                      esMonth: [
                        "Enero,",
                        "Febrero,",
                        "Marzo,",
                        "Abril,",
                        "Mayo,",
                        "Junio,",
                        "Julio,",
                        "Agosto,",
                        "Septiembre,",
                        "Octubre,",
                        "Noviembre,",
                        "Diciembre,",
                      ],
                      enMonth: [
                        "January,",
                        "February,",
                        "March,",
                        "April,",
                        "May,",
                        "June,",
                        "July,",
                        "August,",
                        "September,",
                        "October,",
                        "November,",
                        "December,",
                      ],
                    };
                    let language: any =
                      LocalStorageEncryptService.getFromLocalStorage(
                        "language"
                      );

                    let date: string = moment(
                      invite?.json?.principalForm[4]?.value
                    )
                      .locale("es-mx")
                      .format(
                        `dddd DD [${
                          language == "es" ? "de" : "of"
                        }] MMMM[,] YYYY`
                      );
                    let dateSeparate = date.split(" ");
                    console.log(dateSeparate);
                    
                    let searchDay = obj[
                      `${language == "es" ? "enDay" : "enDay"}`
                    ].findIndex((d) => d == dateSeparate[0]);
                    let searchMonth = obj[
                      `${language == "es" ? "enMonth" : "enMonth"}`
                    ].findIndex((d) => d == dateSeparate[3]);

                    let realDay =
                      obj[`${language == "en" ? "enDay" : "enDay"}`][searchDay];
                    let realMonth =
                      obj[`${language == "en" ? "enMonth" : "enMonth"}`][
                        searchMonth
                      ];

                    return `${t(realDay)} ${dateSeparate[1]} ${t(dateSeparate[2])} ${t(realMonth)} ${dateSeparate[4]}`;
                  })()}
                </p>
                <div className="timer">
                  <div
                    style={{
                      backgroundColor:
                        invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                    }}
                  >
                    <span
                      id="days"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    ></span>
                    <span>{t("invite.d")}</span>
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                    }}
                  >
                    <span
                      id="hours"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    ></span>
                    <span>{t("invite.h")}</span>
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                    }}
                  >
                    <span
                      id="minutes"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    ></span>
                    <span>{t("invite.m")}</span>
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                    }}
                  >
                    <span
                      id="seconds"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    ></span>
                    <span>{t("invite.s")}</span>
                  </div>
                </div>

                {invite?.json?.principalForm[5]?.value && (
                  <div
                    className="bless"
                    style={{
                      backgroundColor:
                        invite?.json?.principalForm[12]?.value ?? "#fff0cd",
                    }}
                  >
                    <p
                      className="father"
                      style={{
                        fontFamily: invite?.json?.principalForm[9]?.value,
                      }}
                    >
                      {t("invite.bless")}
                    </p>

                    <p
                      className="father-title"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {t("invite.our")}
                    </p>

                    <p
                      className="gbride"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {t("invite.bride")}
                    </p>

                    <p
                      className="names"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {invite?.json?.principalForm[5]?.value}
                    </p>
                    <p
                      className="names"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {invite?.json?.principalForm[6]?.value}
                    </p>

                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={svg}
                        alt="weeding"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>

                    <p
                      className="gbride"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {t("invite.groom")}
                    </p>
                    <p
                      className="names"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                      }}
                    >
                      {invite?.json?.principalForm[7]?.value}
                    </p>
                    <p
                      className="names"
                      style={{
                        fontFamily: invite?.json?.principalForm[1]?.value,
                        marginBottom: "120px",
                      }}
                    >
                      {invite?.json?.principalForm[8]?.value}
                    </p>

                    <div
                      className="flowers"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={flowers}
                        alt="weeding"
                        style={{
                          width: "400px",
                        }}
                      />
                    </div>

                    <div
                      className="lecol"
                      style={{
                        backgroundColor:
                          invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                      }}
                    ></div>
                  </div>
                )}
              </div>
            )}

          {invite.json.formMaps && invite.json.formMaps?.length > 0 && (
            <section
              id="top3"
              className="locations"
              style={{
                backgroundColor:
                  invite?.json?.principalForm[12]?.value ?? "#fff0cd",
              }}
            >
              <div
                className="bck"
                style={{
                  backgroundColor:
                    invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                }}
              >
                <p
                  style={{
                    fontFamily: invite?.json?.principalForm[3]?.value,
                  }}
                  className="location"
                >
                  {t("invite.location")}
                </p>

                <section className="maps">
                  {invite?.json?.formMaps?.map((it, index) => {
                    return (
                      <section className="mapind">
                        <p
                          style={{
                            fontWeight: 900,
                            fontSize: "18px",
                          }}
                        >
                          {it[0].value}
                        </p>
                        <ArsaMap
                          extraClass="maper"
                          isMarkedMove={false}
                          idMap={`miMap${index + 1}`}
                          icon={newlyweds}
                          onChangeCoord={(position) => {}}
                        />
                        <p>{it[1].value}</p>
                        <ArsaButton
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${it[0].latLng.lat},${it[0].latLng.lon}`,
                              "_blank"
                            );
                          }}
                        >
                          {t("invite.gmaps")}
                        </ArsaButton>
                      </section>
                    );
                  })}
                </section>
              </div>
            </section>
          )}

          {invite.json.itineraryForm &&
            invite.json.itineraryForm[0] &&
            invite.json.itineraryForm[0][0] && (
              <section
                id="top4"
                className="itinerary"
                style={{
                  backgroundColor:
                    invite?.json?.principalForm[12]?.value ?? "#fff",
                }}
              >
                <p
                  style={{
                    fontFamily: invite?.json?.principalForm[9]?.value,
                  }}
                  className="itinerary-title"
                >
                  {t("invite.itinerary")}
                </p>

                <section className="timeline">
                  {invite?.json.itineraryForm?.map((it, index) => {
                    return (
                      <div
                        className={`timeline-block timeline-block-${
                          index % 2 == 0 ? "right" : "left"
                        }`}
                      >
                        <div
                          className="marker"
                          style={{
                            borderColor:
                              invite?.json?.principalForm[13]?.value ??
                              "#fff0cd",
                          }}
                        >
                          <img src={returnIcon(Number(it[2].value))} alt="" />
                        </div>
                        <div
                          className="timeline-content"
                          style={{
                            backgroundColor:
                              invite?.json?.principalForm[13]?.value ??
                              "#fff0cd",
                            marginLeft: index % 2 == 0 ? "10px" : "0",
                            marginRight: index % 2 != 0 ? "10px" : "0",
                          }}
                        >
                          <h3>{it[0].value}</h3>
                          <p>{it[1].value}</p>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </section>
            )}

          {invite.json.principalForm &&
            invite.json.principalForm[10]?.value &&
            invite.json.principalForm[10]?.value?.length > 0 && (
              <section className="dress-code" id="top5">
                <div className="bck">
                  <p
                    style={{
                      fontFamily: invite?.json?.principalForm[9]?.value,
                    }}
                    className="location"
                  >
                    {t("invite.dress-code")}
                  </p>

                  <img
                    style={{
                      width: "100%",
                      maxWidth: "320px",
                    }}
                    src={
                      invite?.json?.principalForm[10]?.value
                        ? returnCode(
                            Number(invite?.json?.principalForm[10]?.value)
                          )
                        : ""
                    }
                    alt=""
                  />
                </div>
              </section>
            )}

          {invite.json.gifTable &&
            invite.json.gifTable[0] &&
            invite.json.gifTable[0][0] && (
              <section className="gift-table" id="top6">
                <div className="bck">
                  <p
                    style={{
                      fontFamily: invite?.json?.principalForm[9]?.value,
                    }}
                    className="location"
                  >
                    {t("invite.gift-table")}
                  </p>

                  <section className="letmes">
                    {invite?.json?.gifTable.map((gift: any, index) => {
                      return (
                        <div>
                          <p
                            style={{
                              fontFamily: invite?.json?.principalForm[1]?.value,
                            }}
                          >
                            {gift[0].value}
                          </p>

                          <ArsaButton
                            onClick={() => {
                              window.open(gift[1].value, "_blank");
                            }}
                          >
                            {t("invite.tt")}
                          </ArsaButton>
                        </div>
                      );
                    })}
                  </section>

                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={svg}
                      alt="weeding"
                      style={{
                        width: "130px",
                      }}
                    />
                  </div>

                  <section className="msj">
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={gift}
                        alt="weeding"
                        style={{
                          width: "130px",
                        }}
                      />

                      <p>{t("invite.too")}</p>
                    </div>
                  </section>
                </div>
              </section>
            )}

          {invite.image_paths && invite.image_paths.length > 0 && (
            <section className="gift-table" id="top7">
              <div
                className="bck"
                style={{
                  backgroundColor:
                    invite?.json?.principalForm[13]?.value ?? "#fff0cd",
                }}
              >
                <p
                  style={{
                    fontFamily: invite?.json?.principalForm[9]?.value,
                  }}
                  className="location"
                >
                  {t("invite.photos")}
                </p>

                <p className="phrase">
                  {phrase ? phrase[`phrase_${i18n.language}`] : ""}
                </p>

                <section className="galleria" id="legaleri">
                  {invite?.image_paths.split("||").map((img: any, idx) => {
                    return (
                      <div
                        className="photito"
                        id={`photito-${idx}`}
                        key={`photito-${idx}`}
                        onClick={() => {
                          setView(img);
                        }}
                      >
                        <img src={img} alt="image" />

                        <div
                          className="el-close"
                          onClick={(e) => {
                            setView(null);
                            e.stopPropagation();
                          }}
                        >
                          <span className="material-icons">close</span>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </div>
            </section>
          )}

          {invite.json.confirmForm && invite.json.confirmForm?.length > 0 && (
            <section className="formular">
              <ArsaForm
                data={form}
                form={formRef}
                hasAction={true}
                buttonAction={() => {
                  console.log(invite.json);
                  if (
                    form[0].value &&
                    String(form[0].value).length > 0 &&
                    form[2].value &&
                    String(form[2].value).length > 0
                  ) {
                    let request: any = {
                      asunto: "Confirmacion de invitacion",
                      from: "contacto@olam-systems.com.mx",
                      name: form[0].value, //invite.json.confirmForm,
                      to: invite.json.confirmForm ?? "sarrejuan@gmail.com",
                      cuerpo: `<div>
                              <p>${t("invite.asis")}: ${
                        form[1].value ? t("invite.sipi") : t("invite.nop")
                      }</p>
                              <p>${t("invite.subject")}: ${form[0].value}</p>
                              <p>${t("invite.mgh2")}: ${form[2].value}</p>
                            </div>`,
                    };

                    LoadingService.show();
                    HttpService.post(
                      `${environment.environmentData.sendEmail}`,
                      request
                    )
                      .then((responseFile: any) => {
                        LoadingService.hide();
                        let fCopy: any = [...form];
                        fCopy.forEach((element) => {
                          console.log(element);

                          if (element.type == "switch") {
                            element.value = false;
                          } else {
                            element.value = "";
                            (document.getElementById(element.id) as any).value =
                              "";
                          }
                        });

                        setForm(fCopy);
                        AlertService.bottomModals(
                          t("alerts.gud"),
                          t("alerts.need2")
                        );
                      })
                      .catch((e) => {
                        console.log(e);

                        LoadingService.hide();
                        AlertService.bottomModals(
                          t("alerts.error"),
                          t("alerts.embarrassing")
                        );
                      });
                  }
                }}
                buttonText={t("invite.confirm")}
                extraClass={"confirms-form"}
                onChangeForm={(e, index) => {
                  console.log(e);
                  if (e.type == "switch") {
                  }
                }}
              ></ArsaForm>
            </section>
          )}

          {invite?.url_sound && (
            <audio src={`${invite.url_sound}`} id="soundtrack" loop></audio>
          )}

          <ArsaFabButton
            extraClass="fabiruchis megafab"
            onClick={() => {
              playSound();
            }}
            id={123}
          >
            <span className="material-icons">{`${
              !pause ? "pause" : "play_arrow"
            }`}</span>
          </ArsaFabButton>

          <div className="menu">
            <ArsaFabButton
              extraClass="fabiruchis menufab"
              onClick={() => {
                openNav();
              }}
              id={1234}
            >
              <span className="material-icons">menu</span>
            </ArsaFabButton>
          </div>
        </section>
      )}

      {view && (
        <section className="legalerisuper">
          <div>
            <img src={view} />

            <div
              className="el-close"
              onClick={(e) => {
                setView(null);
                e.stopPropagation();
              }}
            >
              <span className="material-icons">close</span>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
