import { useTranslation } from "react-i18next";
import "./register.scss";

import {
  ArsaContainer,
  useDeviceDetect,
  useTheme,
  ArsaForm,
  UtilService,
} from "arsaLocalv4";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import RegisterPresenter, {
  registerForm,
} from "./presenter/register.presenter";
import { getLinearProgressUtilityClass } from "@mui/material";
import loaderGif from "./../../assets/imgs/gifs/loader.gif";
import { UtilLocalService } from "../../services/UtilLocalService";

import { cameraOutline, imageOutline, archiveOutline } from "ionicons/icons";

function Register({}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formRegister, setRegisterForm] = useState(registerForm);
  const [picture, setPicture] = useState<any>(null);
  const formReferences = useRef(null);
  const { theme } = useTheme();

  useEffect(() => {
    init();
  }, []);

  function init() {}

  async function opcionesIMG(opc: number, isMultiple: boolean = false) {
    UtilLocalService.openActionSeets([
     /*  {
        text: t("PHOTO"),
        icon: cameraOutline,
        handler: async () => {
          setPicture(await UtilLocalService.takeFoto()); //this.takeFoto(true, isMultiple);
        },
      },
      {
        text: t("BUTTON-9"),
        icon: imageOutline,
        handler: async () => {
          setPicture(await UtilLocalService.takeFoto(false));
        },
      }, */
      {
        text: t("FILES"),
        icon: archiveOutline,
        handler: async () => {
          setPicture(
            await UtilLocalService.openFile({
              t: t,
            })
          );
        },
      },
      {
        text: t("alerts.cancel"),
        role: "cancel",
        handler: () => {},
      },
    ]);
  }

  function validateForm(evaluatePassword: boolean = false) {
    RegisterPresenter.validateFirstStep(
      formRegister,
      setRegisterForm,
      evaluatePassword,
      t
    );
  }

  return (
    <>
      <ArsaHeader
        title={t("register.title")}
        applyPadding={true}
        backActive={true}
        menuActive={false}
        backAction={() => {
          navigate(-1);
        }}
      />
      <ArsaContainer customId={"main-content"} applyPadding={true}>
        <ArsaForm
          data={formRegister}
          form={formReferences}
          onChangeForm={(e,index)=>{
            validateForm();
          }}
          buttonAction={() => {
            RegisterPresenter.sendForm(formRegister, setRegisterForm, t, i18n, picture, navigate);
          }}
          buttonText={t("register.ok")}
        >
          <div
            className="regex-errors"
            key={`regexes-${UtilService.getRandomText(9)}`}
          >
            {formRegister.map((itemForm: any) => {
              if (
                itemForm.regexes &&
                itemForm.value &&
                itemForm.value?.length > 0
              ) {
                itemForm.regexes.map((regex: any) => {
                  regex.isValid = regex.regex.test(itemForm.value);
                });
                let findRegexError = itemForm.regexes.filter(
                  (regex: any) => !regex.isValid
                );
                if (findRegexError.length > 0) {
                  return itemForm.regexes.map((regex: any) => {
                    return (
                      <div
                        className="regex-print"
                        key={`regex-${UtilService.getRandomText(9)}`}
                        style={{
                          color: theme.color,
                        }}
                      >
                        <span className={regex.isValid ? "valid-regex" : ""}>
                          ⬤
                        </span>{" "}
                        {t(regex.message)}
                      </div>
                    );
                  });
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </div>

          <div
            className="technicians"
            style={{
              backgroundColor: theme.color,
            }}
          >
            <div
              style={{
                backgroundColor: theme.background,
              }}
            >
              <p
                style={{
                  color: theme.color,
                  margin: "0",
                }}
              >
                {t("register.profile-image")}
              </p>
            </div>
          </div>

          <div
            className="contenedor-logo"
            style={{
              borderColor: theme.color,
            }}
          >
            <label
              className="llabel"
              onClick={() => {
                opcionesIMG(1, false);
              }}
            >
              {!picture && <img src={loaderGif} className="w-picture" />}
              {picture && <img src={picture} className="w-picture" />}
            </label>

            <p
              style={{
                color: theme.color,
                verticalAlign: "middle",
              }}
            >
              {t("register.images")}
            </p>
          </div>
        </ArsaForm>
      </ArsaContainer>
    </>
  );
}

export default Register;
