import {
  ArsaButton,
  ArsaContainer,
  ArsaFabButton,
  ArsaMap,
  ArsaSpinner,
  EventService,
  LoadingService,
  UtilService,
  useTheme,
} from "arsaLocalv4";
import {
  archiveOutline,
  cameraOutline,
  imageOutline,
  pause,
  play,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import textInput from "../../assets/svg/textInput.svg";
import { ArsaForm } from "../../components/arsa-form/ArsaForm";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { UtilLocalService } from "../../services/UtilLocalService";
import loaderGif from "./../../assets/imgs/gifs/loader.gif";
import newlyweds from "./../../assets/imgs/invites/newlyweds.png";
import soundImg from "./../../assets/imgs/sonido.png";
import "./listInvites.scss";
import {
  CONFIRM_MAIL,
  INITIAL_CRUD_FORM_INVITE,
  InvitePresenter,
  PERMISSIONS_INVITE,
} from "./presenter/Invite.presenter";

import dateInput from "../../assets/svg/dateInput.svg";

import tipo1 from "../../assets/imgs/invites/tipo1.png";
import tipo2 from "../../assets/imgs/invites/tipo2.png";
import tipo3 from "../../assets/imgs/invites/tipo3.png";
import tipo4 from "../../assets/imgs/invites/tipo4.png";
import tipo5 from "../../assets/imgs/invites/tipo5.png";
import tipo6 from "../../assets/imgs/invites/tipo6.png";
import tipo7 from "../../assets/imgs/invites/tipo7.png";

import logoApp from "../../assets/imgs/logos/logoApp.png";

import code1 from "../../assets/imgs/invites/code1.png";
import code2 from "../../assets/imgs/invites/code2.png";
import code3 from "../../assets/imgs/invites/code3.png";
import code4 from "../../assets/imgs/invites/code4.png";
import code5 from "../../assets/imgs/invites/code5.png";
import code6 from "../../assets/imgs/invites/code6.png";

import icon0 from "../../assets/imgs/invites/icon0.png";
import icon1 from "../../assets/imgs/invites/icon1.png";
import icon2 from "../../assets/imgs/invites/icon2.png";
import icon3 from "../../assets/imgs/invites/icon3.png";
import icon4 from "../../assets/imgs/invites/icon4.png";
import icon5 from "../../assets/imgs/invites/icon5.png";
import icon6 from "../../assets/imgs/invites/icon6.png";
import icon7 from "../../assets/imgs/invites/icon7.png";
import icon8 from "../../assets/imgs/invites/icon8.png";
import icon9 from "../../assets/imgs/invites/icon9.png";

const abortController = new AbortController();
const signal = abortController.signal;

export function InviteCrud({ data, close, modal, loadInvites }) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [form, setForm] = useState([]);
  const formRef = useRef(new Date().getTime());
  const [picture, setPicture] = useState<any>(null);
  const [paused, setPause] = useState<any>(true);
  const [isEdit, setEdit] = useState<any>(false);
  const [load, setLoad] = useState<any>(true);
  const [sound, setSound] = useState<any>(null);
  const [privateForm, setPrivateForm] = useState(PERMISSIONS_INVITE);
  const [confirmMailForm, setConfirmMailForm] = useState(CONFIRM_MAIL);
  const [productImages, setProductImages] = useState([]);

  const [formMaps, setFormMaps] = useState([
    [
      {
        type: "text",
        value: null,
        label: "invite.map-where",
        placeholder: "invite.map-where-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={textInput} alt="" />
          </div>
        ),
      },
      {
        type: "text",
        value: null,
        label: "invite.map-msg",
        placeholder: "invite.map-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={textInput} alt="" />
          </div>
        ),
      },
    ],
  ]);

  const [itinerary, setItinerary] = useState([
    [
      {
        type: "text",
        value: null,
        label: "invite.itinerary-msg",
        placeholder: "invite.itinerary-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={textInput} alt="" />
          </div>
        ),
      },
      {
        type: "time",
        realType: "time",
        value: null,
        label: "invite.time-msg",
        placeholder: "invite.time-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={dateInput} alt="" />
          </div>
        ),
      },
      {
        type: "dropdown-abs",
        value: null,
        label: "invite.icon-msg",
        placeholder: "invite.icon-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input-codes",
        values: [
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(0)} alt="" />
              </div>
            ),
            value: "0",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(1)} alt="" />
              </div>
            ),
            value: "1",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(2)} alt="" />
              </div>
            ),
            value: "2",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(3)} alt="" />
              </div>
            ),
            value: "3",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(4)} alt="" />
              </div>
            ),
            value: "4",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(5)} alt="" />
              </div>
            ),
            value: "5",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(6)} alt="" />
              </div>
            ),
            value: "6",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(7)} alt="" />
              </div>
            ),
            value: "7",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(8)} alt="" />
              </div>
            ),
            value: "8",
            active: false,
          },
          {
            label: (
              <div className="drop-content1">
                <img src={returnIcon(9)} alt="" />
              </div>
            ),
            value: "9",
            active: false,
          },
        ],
        id: "qwertyLetter",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={dateInput} alt="" />
          </div>
        ),
      },
    ],
  ]);

  const [gifTable, setGifTable] = useState<any>([
    [
      {
        type: "text",
        value: null,
        label: "invite.gif-msg",
        placeholder: "invite.gif-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={textInput} alt="" />
          </div>
        ),
      },
      {
        type: "text",
        value: null,
        label: "invite.gif2-msg",
        placeholder: "invite.gif2-msg-place",
        required: true,
        hasTopLabel: true,
        hasError: false,
        error: "invite.errors.name",
        globalExtraClass: "invite-input",
        extraLeftComponent: (
          <div className="custom-icon-left">
            <img src={textInput} alt="" />
          </div>
        ),
      },
    ],
  ]);

  const formRefMap1 = useRef(new Date().getTime());
  const formRefMap2 = useRef(new Date().getTime());

  useEffect(() => {
    try {
      (document.getElementById("menuApp") as any).style.display = "block";
    } catch (error) {}
    initFonts();
  }, []);

  async function initFonts() {
    let fonts: any = [
      "ABeeZee-Regular.ttf",
      "Abel-Regular.ttf",
      "AbhayaLibre-Regular.ttf",
      "Aboreto-Regular.ttf",
      "AbrilFatface-Regular.ttf",
      "AbyssinicaSIL-Regular.ttf",
      "Acme-Regular.ttf",
      "Actor-Regular.ttf",
      "Adamina-Regular.ttf",
      "ADLaMDisplay-Regular.ttf",
      "AdobeBlank-Regular.ttf",
      "Agbalumo-Regular.ttf",
      "Agdasima-Regular.ttf",
      "AguafinaScript-Regular.ttf",
      "Akatab-Regular.ttf",
      "AkayaKanadaka-Regular.ttf",
      "AkayaTelivigala-Regular.ttf",
      "Akronim-Regular.ttf",
      "AksaraBaliGalang-Regular.ttf",
      "Aladin-Regular.ttf",
      "Alata-Regular.ttf",
      "Alatsi-Regular.ttf",
      "Aldrich-Regular.ttf",
      "Alef-Regular.ttf",
      "AlegreyaSans-Regular.ttf",
      "AlegreyaSansSC-Regular.ttf",
      "AlegreyaSC-Regular.ttf",
      "AlexBrush-Regular.ttf",
      "AlfaSlabOne-Regular.ttf",
      "Alice-Regular.ttf",
      "AlikeAngular-Regular.ttf",
      "Alike-Regular.ttf",
      "Alkalami-Regular.ttf",
      "Allan-Regular.ttf",
      "Allerta-Regular.ttf",
      "AllertaStencil-Regular.ttf",
      "Allison-Regular.ttf",
      "Allura-Regular.ttf",
      "Almarai-Regular.ttf",
      "AlmendraDisplay-Regular.ttf",
      "Almendra-Regular.ttf",
      "AlmendraSC-Regular.ttf",
      "AlumniSansCollegiateOne-Regular.ttf",
      "AlumniSansInlineOne-Regular.ttf",
      "AlumniSansPinstripe-Regular.ttf",
      "Amarante-Regular.ttf",
      "Amaranth-Regular.ttf",
      "AmaticSC-Regular.ttf",
      "Amethysta-Regular.ttf",
      "Amiko-Regular.ttf",
      "AmiriQuran-Regular.ttf",
      "Amiri-Regular.ttf",
      "Amita-Regular.ttf",
      "Anaheim-Regular.ttf",
      "Andika-Regular.ttf",
      "Angkor-Regular.ttf",
      "AnnapurnaSIL-Regular.ttf",
      "AnnieUseYourTelescope-Regular.ttf",
      "AnonymousPro-Regular.ttf",
      "Anta-Regular.ttf",
      "AnticDidone-Regular.ttf",
      "Antic-Regular.ttf",
      "AnticSlab-Regular.ttf",
      "Anton-Regular.ttf",
      "AoboshiOne-Regular.ttf",
      "Arapey-Regular.ttf",
      "Arbutus-Regular.ttf",
      "ArbutusSlab-Regular.ttf",
      "ArchitectsDaughter-Regular.ttf",
      "ArchivoBlack-Regular.ttf",
      "ArefRuqaaInk-Regular.ttf",
      "ArefRuqaa-Regular.ttf",
      "AreYouSerious-Regular.ttf",
      "Arizonia-Regular.ttf",
      "Armata-Regular.ttf",
      "Arsenal-Regular.ttf",
      "Artifika-Regular.ttf",
      "Arvo-Regular.ttf",
      "Arya-Regular.ttf",
      "AsapCondensed-Regular.ttf",
      "Asar-Regular.ttf",
      "Asset-Regular.ttf",
      "Astloch-Regular.ttf",
      "Asul-Regular.ttf",
      "Athiti-Regular.ttf",
      "AtkinsonHyperlegible-Regular.ttf",
      "Atma-Regular.ttf",
      "AtomicAge-Regular.ttf",
      "Aubrey-Regular.ttf",
      "Audiowide-Regular.ttf",
      "AutourOne-Regular.ttf",
      "Average-Regular.ttf",
      "AverageSans-Regular.ttf",
      "AveriaGruesaLibre-Regular.ttf",
      "AveriaLibre-Regular.ttf",
      "AveriaSansLibre-Regular.ttf",
      "AveriaSerifLibre-Regular.ttf",
      "B612Mono-Regular.ttf",
      "B612-Regular.ttf",
      "Babylonica-Regular.ttf",
      "BacasimeAntique-Regular.ttf",
      "BadScript-Regular.ttf",
      "BagelFatOne-Regular.ttf",
      "Bahiana-Regular.ttf",
      "Bahianita-Regular.ttf",
      "BaiJamjuree-Regular.ttf",
      "BakbakOne-Regular.ttf",
      "BalsamiqSans-Regular.ttf",
      "Balthazar-Regular.ttf",
      "Bangers-Regular.ttf",
      "BarlowCondensed-Regular.ttf",
      "Barlow-Regular.ttf",
      "BarlowSemiCondensed-Regular.ttf",
      "Barriecito-Regular.ttf",
      "Barrio-Regular.ttf",
      "Basic-Regular.ttf",
      "Baskervville-Regular.ttf",
      "Battambang-Regular.ttf",
      "Baumans-Regular.ttf",
      "Bayon-Regular.ttf",
      "BeauRivage-Regular.ttf",
      "BebasNeue-Regular.ttf",
      "Belanosima-Regular.ttf",
      "Belgrano-Regular.ttf",
      "Bellefair-Regular.ttf",
      "Belleza-Regular.ttf",
      "Bellota-Regular.ttf",
      "BellotaText-Regular.ttf",
      "BenchNine-Regular.ttf",
      "Benne-Regular.ttf",
      "Bentham-Regular.ttf",
      "BerkshireSwash-Regular.ttf",
      "BethEllen-Regular.ttf",
      "Bevan-Regular.ttf",
      "BeVietnamPro-Regular.ttf",
      "Bhavuka-Regular.ttf",
      "BhuTukaExpandedOne-Regular.ttf",
      "BigelowRules-Regular.ttf",
      "BigshotOne-Regular.ttf",
      "Bilbo-Regular.ttf",
      "BilboSwashCaps-Regular.ttf",
      "BioRhymeExpanded-Regular.ttf",
      "BirthstoneBounce-Regular.ttf",
      "Birthstone-Regular.ttf",
      "Biryani-Regular.ttf",
      "BIZUDGothic-Regular.ttf",
      "BIZUDMincho-Regular.ttf",
      "BIZUDPGothic-Regular.ttf",
      "BIZUDPMincho-Regular.ttf",
      "BlackAndWhitePicture-Regular.ttf",
      "BlackHanSans-Regular.ttf",
    ];

    let formTmp: any = [...INITIAL_CRUD_FORM_INVITE];
    try {
      fonts.forEach((font, index) => {
        if (index < 152) {
          formTmp.forEach((element) => {
            if (element.id?.includes("letters")) {
              element.values.push({
                value: `${font.replace("-Regular.ttf", "")}`,
                //label: font.family
                label: (
                  <section
                    style={{
                      fontFamily: font.replace("-Regular.ttf", ""),
                    }}
                  >
                    {UtilService.getRandomText(6)}
                  </section>
                ),
              });
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoad(false);
    formTmp[11].values = [
      {
        label: (
          <div className="drop-type">
            <img src={tipo1} alt="" />
            <p>{t("invite.tipo1")}</p>
          </div>
        ),
        value: "1",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo2} alt="" />
            <p>{t("invite.tipo2")}</p>
          </div>
        ),
        value: "2",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo3} alt="" />
            <p>{t("invite.tipo3")}</p>
          </div>
        ),
        value: "3",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo4} alt="" />
            <p>{t("invite.tipo4")}</p>
          </div>
        ),
        value: "4",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo5} alt="" />
            <p>{t("invite.tipo5")}</p>
          </div>
        ),
        value: "5",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo6} alt="" />
            <p>{t("invite.tipo6")}</p>
          </div>
        ),
        value: "6",
        active: false,
      },
      {
        label: (
          <div className="drop-type">
            <img src={tipo7} alt="" />
            <p>{t("invite.tipo7")}</p>
          </div>
        ),
        value: "7",
        active: false,
      },
    ];
    setForm(formTmp);

    if (data.edit && data.invite) {
      let privateFormCopy = [...privateForm];
      privateFormCopy[0].value = data.invite.is_public_event == 0;

      let copyPrincipalForm: any = [...formTmp];
      let confirmMailFormCopy: any = [...confirmMailForm];
      confirmMailFormCopy[0].value = data.invite?.json_invite.confirmForm
      if (data.invite.url_img) {
        /* setPicture(
          await UtilService.getBase64(
            await UtilService.createFileFromUrl(data.invite.url_img, "test")
          )
        ); */
        setPicture(data.invite.url_img);
      }

      if (data.invite.url_sound) {
        /* setSound(
          await UtilService.getBase64(
            await UtilService.createFileFromUrl(data.invite.url_sound, "sound")
          )
        ); */
        setSound(data.invite.url_sound);
      }

      data.invite.json_invite.principalForm.forEach((itm: any) => {
        if (copyPrincipalForm[itm.index]) {
          if (
            copyPrincipalForm[itm.index].id &&
            copyPrincipalForm[itm.index].id.includes("letters")
          ) {
            setTimeout(() => {
              copyPrincipalForm[itm.index].values.forEach((selection: any) => {
                if (itm.value == selection.value) {
                  selection.selected = true;
                  copyPrincipalForm[itm.index].value = selection.value;
                }
              });
              
              let id: any = document.getElementById(
                `common-hdr-${copyPrincipalForm[itm.index].id}`
              );
              renderRoot(
                id.children[0],
                <div className="drop-content-dynamic" style={{ width: "100%" }}>
                  <p
                    style={{
                      fontFamily: itm.value,
                      color: "#484848",
                    }}
                  >
                    {itm.value}
                  </p>
                </div>
              );
            }, 500);
          } else if (
            copyPrincipalForm[itm.index].id &&
            (copyPrincipalForm[itm.index].id.includes("codeId") ||
              copyPrincipalForm[itm.index].id.includes("typeId"))
          ) {
            setTimeout(() => {
              let selectioned: any = null;
              copyPrincipalForm[itm.index].values.forEach((selection: any) => {
                if (itm.value == selection.value) {
                  selection.selected = true;
                  copyPrincipalForm[itm.index].value = selection.value;
                  selectioned = selection;
                }
              });

              let id: any = document.getElementById(
                `common-hdr-${copyPrincipalForm[itm.index].id}`
              );

              if(selectioned){
                renderRoot(
                  id.children[0],
                  <div className="drop-content-dynamic" style={{ width: "40px" }}>
                    {copyPrincipalForm[itm.index].id.includes("codeId") ? (
                      <img
                        style={{ width: "80%" }}
                        src={returnCode(Number(selectioned?.value))}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ width: "80%" }}
                        src={returnType(Number(selectioned?.value))}
                        alt=""
                      />
                    )}
                  </div>
                );
              }
            }, 500);
          } else {
            copyPrincipalForm[itm.index].value = itm.value;
          }
        }
      });

      setForm(copyPrincipalForm);

      let copyItineraryForm: any = [];
      data.invite.json_invite.itineraryForm.forEach((itm: any) => {
        let ledi = UtilService.getRandomNumber(10);
        copyItineraryForm.push([
          {
            type: "text",
            value: itm[0]?.value,
            label: "invite.itinerary-msg",
            placeholder: "invite.itinerary-msg-place",
            required: true,
            hasTopLabel: true,
            hasError: false,
            error: "invite.errors.name",
            globalExtraClass: "invite-input",
            extraLeftComponent: (
              <div className="custom-icon-left">
                <img src={textInput} alt="" />
              </div>
            ),
          },
          {
            type: "time",
            realType: "time",
            value: itm[1]?.value,
            label: "invite.time-msg",
            placeholder: "invite.time-msg-place",
            required: true,
            hasTopLabel: true,
            hasError: false,
            error: "invite.errors.name",
            globalExtraClass: "invite-input",
            extraLeftComponent: (
              <div className="custom-icon-left">
                <img src={dateInput} alt="" />
              </div>
            ),
          },
          {
            type: "dropdown-abs",
            value: itm[2]?.value,
            label: "invite.icon-msg",
            placeholder: "invite.icon-msg-place",
            required: true,
            hasTopLabel: true,
            hasError: false,
            error: "invite.errors.name",
            globalExtraClass: "invite-input-codes",
            values: [
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(0)} alt="" />
                  </div>
                ),
                value: "0",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(1)} alt="" />
                  </div>
                ),
                value: "1",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(2)} alt="" />
                  </div>
                ),
                value: "2",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(3)} alt="" />
                  </div>
                ),
                value: "3",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(4)} alt="" />
                  </div>
                ),
                value: "4",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(5)} alt="" />
                  </div>
                ),
                value: "5",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(6)} alt="" />
                  </div>
                ),
                value: "6",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(7)} alt="" />
                  </div>
                ),
                value: "7",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(8)} alt="" />
                  </div>
                ),
                value: "8",
                active: false,
              },
              {
                label: (
                  <div className="drop-content1">
                    <img src={returnIcon(9)} alt="" />
                  </div>
                ),
                value: "9",
                active: false,
              },
            ],
            id: ledi,
            extraLeftComponent: (
              <div className="custom-icon-left">
                <img src={dateInput} alt="" />
              </div>
            ),
          },
        ]);

        setTimeout(() => {
          let id: any = document.getElementById(`common-hdr-${ledi}`);
          renderRoot(
            id.children[0],
            <div className="drop-content-dynamic" style={{ width: "40px" }}>
              <img
                style={{ width: "80%" }}
                src={returnIcon(Number(itm[2].value))}
                alt=""
              />
            </div>
          );
        }, 500);
      });
      setItinerary(copyItineraryForm);
      let copyGifForm: any = [];
      if (data.invite.json_invite.gifTable) {
        data.invite.json_invite.gifTable.forEach((itm: any) => {
          let ledi = UtilService.getRandomNumber(10);
          copyGifForm.push([
            {
              type: "text",
              value: itm[0]?.value,
              label: "invite.gif-msg",
              placeholder: "invite.gif-msg-place",
              required: true,
              hasTopLabel: true,
              hasError: false,
              error: "invite.errors.name",
              globalExtraClass: "invite-input",
              extraLeftComponent: (
                <div className="custom-icon-left">
                  <img src={textInput} alt="" />
                </div>
              ),
            },
            {
              type: "text",
              value: itm[1]?.value,
              label: "invite.gif2-msg",
              placeholder: "invite.gif2-msg-place",
              required: true,
              hasTopLabel: true,
              hasError: false,
              error: "invite.errors.name",
              globalExtraClass: "invite-input",
              extraLeftComponent: (
                <div className="custom-icon-left">
                  <img src={textInput} alt="" />
                </div>
              ),
            },
          ]);
        });
      }

      if (data.invite.json_invite.formMaps) {
        //let copyFormMaps: any = JSON.parse(JSON.stringify([...formMaps]));
        let copyFormMaps: any = [];

        data.invite.json_invite.formMaps.forEach((itm: any, index: number) => {
          let ledi = UtilService.getRandomNumber(10);
          copyFormMaps.push([
            {
              type: "text",
              value: itm[0]?.value,
              latLng: itm[0]?.latLng,
              label: "invite.map-where",
              placeholder: "invite.map-where-place",
              required: true,
              hasTopLabel: true,
              hasError: false,
              error: "invite.errors.name",
              globalExtraClass: "invite-input",
              extraLeftComponent: (
                <div className="custom-icon-left">
                  <img src={textInput} alt="" />
                </div>
              ),
            },
            {
              type: "text",
              value: itm[1]?.value,
              label: "invite.map-msg",
              placeholder: "invite.map-msg-place",
              required: true,
              hasTopLabel: true,
              hasError: false,
              error: "invite.errors.name",
              globalExtraClass: "invite-input",
              extraLeftComponent: (
                <div className="custom-icon-left">
                  <img src={textInput} alt="" />
                </div>
              ),
            },
          ]);

          try {
            let mapita: any = {};

            mapita.latLng = {
              lat: itm[0]?.latLng.lat,
              lon: itm[0]?.latLng.lon ? itm[0]?.latLng.lon : itm[0]?.latLng.lng,
            };

            setTimeout(() => {
              EventService.send(
                `updatePointMap-miMap${index + 1}`,
                mapita.latLng
              );
              setTimeout(() => {
                EventService.send(
                  `updatePointMap-miMap${index + 1}`,
                  mapita.latLng
                );
              }, 1500);
            }, 1500);
          } catch (error) {
            console.log(error);
          }
        });
        setFormMaps(copyFormMaps);
      }

      setGifTable(copyGifForm);

      let b64Images: any = [];
      let paths: any =null;
      try {
        paths = data.invite.image_paths.split("||");
      } catch (error) {
        
      }

      try {
        if (paths && paths.length > 0) {
          for (const imageHttp of paths) {
            b64Images.push(
              await UtilService.getBase64(
                await UtilService.createFileFromUrl(imageHttp, "sound")
              )
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
      setProductImages(b64Images);
    } else {
      let copyPrincipalForm: any = [...formTmp];
      copyPrincipalForm.forEach((element) => {
        element.value = null;
      });
      setForm(copyPrincipalForm);
    }
  }

  async function opcionesIMG(opc: number, isMultiple: boolean = false) {
    UtilLocalService.openActionSeets([
     /*  {
        text: t("PHOTO"),
        icon: cameraOutline,
        handler: async () => {
          let image: any = await UtilLocalService.takeFoto();
          if (isMultiple) {
            let productImagesTmp: any = [...productImages];
            productImagesTmp.push(image);
            setProductImages(productImagesTmp);
          } else {
            setPicture(image);
          }
        },
      },
      {
        text: t("BUTTON-9"),
        icon: imageOutline,
        handler: async () => {
          let image: any = await UtilLocalService.takeFoto(false);
          if (isMultiple) {
            let productImagesTmp: any = [...productImages];
            productImagesTmp.push(image);
            setProductImages(productImagesTmp);
          } else {
            setPicture(image);
          }
        },
      }, */
      {
        text: t("FILES"),
        icon: archiveOutline,
        handler: async () => {
          let image: any = await UtilLocalService.openFile({
            t: t,
          });
          if (isMultiple) {
            let productImagesTmp: any = [...productImages];
            productImagesTmp.push(image);
            setProductImages(productImagesTmp);
          } else {
            setPicture(image);
          }
        },
      },
      {
        text: t("alerts.cancel"),
        role: "cancel",
        handler: () => {},
      },
    ]);
  }

  async function opcionesSOUND(opc: number, isMultiple: boolean = false) {
    UtilLocalService.openActionSeets([
      {
        text: t("FILES"),
        icon: archiveOutline,
        handler: async () => {
          setSound(
            await UtilLocalService.openFile({
              acceptedFiles: ["audio/mp3", "audio/mpeg", "audio/mp4"],
              t: t,
            })
          );
        },
      },
      {
        text: t("alerts.cancel"),
        role: "cancel",
        handler: () => {},
      },
    ]);
  }

  function returnCode(value) {
    switch (value) {
      case 1:
        return code1;
      case 2:
        return code2;
      case 3:
        return code3;
      case 4:
        return code4;
      case 5:
        return code5;
      case 6:
        return code6;
      default:
        return "";
    }
  }

  function returnType(value) {
    switch (value) {
      case 1:
        return tipo1;
      case 2:
        return tipo2;
      case 3:
        return tipo3;
      case 4:
        return tipo4;
      case 5:
        return tipo5;
      case 6:
        return tipo6;
      case 7:
        return tipo7;
      default:
        return "";
    }
  }

  function returnIcon(value) {
    switch (value) {
      case 0:
        return icon0;
      case 1:
        return icon1;
      case 2:
        return icon2;
      case 3:
        return icon3;
      case 4:
        return icon4;
      case 5:
        return icon5;
      case 6:
        return icon6;
      case 7:
        return icon7;
      case 8:
        return icon8;
      case 9:
        return icon9;
      default:
        return "";
    }
  }

  function renderRoot(container, content) {
    const root = createRoot(container);
    // Lógica para renderizar el árbol de componentes en el contenedor
    root.render(content);
  }

  return (
    <>
      <ArsaHeader
        title={t(data.edit ? "catalogs.edit" : "catalogs.create")}
        applyPadding={true}
        isModal={true}
        closeModal={() => {
          //onDismiss();
          close();
        }}
      />
      <ArsaContainer
        customId={"main-content-crud"}
        applyPadding={false}
        extraClass={"catalogs-module"}
        themeBackgroundActive={false}
      >
        {data && data.invite && (
          <section className="img-profile">
            <p
              style={{
                color: theme.color,
              }}
            >
              {data.edit ? t("catalogs.edit-a") : t("catalogs.create-a")}{" "}
              {data?.invite?.json_invite?.principalForm[0]?.value
                ? data.invite.json_invite.principalForm[0].value
                : t("invite.not-found")}
            </p>

            <div className="ctn-im">
              <div className="interior">
                <img
                  src={data.invite.url_img ? data.invite.url_img : logoApp}
                  className="img-logo"
                />
              </div>
            </div>
          </section>
        )}

        <div
          className="technicians"
          style={{
            backgroundColor: theme.color,
          }}
        >
          <div
            style={{
              backgroundColor: theme.background,
            }}
          >
            <p
              style={{
                color: theme.color,
                margin: "0",
              }}
            >
              {t("invite.image-title")}
            </p>
          </div>
        </div>

        <div
          className="contenedor-logo"
          style={{
            borderColor: theme.color,
          }}
        >
          <label
            className="llabel"
            onClick={() => {
              opcionesIMG(1, false);
            }}
          >
            {!picture && <img src={loaderGif} className="w-picture" />}
            {picture && <img src={picture} className="w-picture" />}
          </label>

          <p
            style={{
              color: theme.color,
              verticalAlign: "middle",
            }}
          >
            {t("invite.images")}
          </p>
        </div>

        <div
          className="technicians"
          style={{
            backgroundColor: theme.color,
          }}
        >
          <div
            style={{
              backgroundColor: theme.background,
            }}
          >
            <p
              style={{
                color: theme.color,
                margin: "0",
              }}
            >
              {t("invite.sound-title")}
            </p>
          </div>
        </div>

        <div
          className="contenedor-logo"
          style={{
            borderColor: theme.color,
          }}
        >
          <label
            className="llabel sound"
            onClick={() => {
              opcionesSOUND(1, false);
            }}
          >
            {!sound && <img src={soundImg} className="w-picture" />}
            {sound && (
              <section>
                <img src={soundImg} className="w-picture" />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    let id: any = document.getElementById("myAudio");

                    if (!id.paused) {
                      id.pause();
                      setPause(true);
                    } else {
                      id.play();
                      setPause(false);
                    }
                  }}
                >
                  <img src={paused ? play : pause} alt="music" />
                </div>
              </section>
            )}
          </label>

          <p
            style={{
              color: theme.color,
              verticalAlign: "middle",
            }}
          >
            {t("invite.sounds")}
          </p>
        </div>

        {!load ? (
          <>
            <ArsaForm
              data={privateForm}
              form={formRefMap2}
              hasAction={false}
              buttonAction={() => {}}
              onChangeForm={(e, index) => {
                console.log(e);
                e.value = !e.value;
                e.checked = e.value;
              }}
              buttonText={t("alerts.accept")}
              extraClass={"crud-form"}
              onBlurForm={async (e, index) => {}}
            ></ArsaForm>

            <ArsaForm
              data={confirmMailForm}
              form={formRefMap2}
              hasAction={false}
              buttonAction={() => {}}
              onChangeForm={(e, index) => {
                console.log(e);
                
              }}
              buttonText={t("alerts.accept")}
              extraClass={"crud-form"}
              onBlurForm={async (e, index) => {}}
            ></ArsaForm>

            <ArsaForm
              data={form}
              form={formRef}
              hasAction={false}
              buttonAction={() => {}}
              buttonText={t("alerts.accept")}
              extraClass={"crud-form"}
              onChangeForm={(e, index) => {
                let copy: any = [...form];
                switch (e.type) {
                  case "dropdown-abs":
                    if (e.id.includes("letters")) {
                      let selected: any = e.values.filter((s) => s.selected);
                      copy[index].value = selected[0].value;

                      let id: any = document.getElementById(
                        `common-hdr-${e.id}`
                      );

                      renderRoot(
                        id.children[0],
                        <div
                          className="drop-content-dynamic"
                          style={{ width: "100%" }}
                        >
                          <p
                            style={{
                              fontFamily: selected[0].value,
                              color: "#484848",
                            }}
                          >
                            {selected[0].value}
                          </p>
                        </div>
                      );

                      setTimeout(() => {
                        id.click();
                      }, 100);
                    } else if (
                      e.id.includes("codeId") ||
                      e.id.includes("typeId")
                    ) {
                      let selected: any = e.values.filter((s) => s.selected);
                      copy[index].value = selected[0].value;

                      let id: any = document.getElementById(
                        `common-hdr-${e.id}`
                      );

                      console.log(e);

                      renderRoot(
                        id.children[0],
                        <div
                          className="drop-content-dynamic"
                          style={{ width: "40px" }}
                        >
                          {e.id.includes("codeId") ? (
                            <img
                              style={{ width: "80%" }}
                              src={returnCode(Number(selected[0].value))}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ width: "80%" }}
                              src={returnType(Number(selected[0].value))}
                              alt=""
                            />
                          )}
                        </div>
                      );

                      setTimeout(() => {
                        id.click();
                      }, 100);
                    }
                    break;
                }
              }}
            >
              <div
                style={{
                  position: "relative",
                  marginBottom: "50px",
                }}
              >
                {" "}
                {formMaps.map((formIteration: any, indexf: number) => {
                  return (
                    <>
                      <section className="map-ctnair">
                        <p>{t("invite.map1") + " " + (indexf + 1)}</p>
                        <ArsaForm
                          data={formIteration}
                          form={formRefMap1}
                          hasAction={false}
                          buttonAction={() => {}}
                          buttonText={t("alerts.accept")}
                          extraClass={"crud-form"}
                          onBlurForm={async (e, index) => {
                            if (e.value && e.value.length > 0) {
                              let response: any =
                                await InvitePresenter.getLatLng(
                                  e.value,
                                  signal
                                );
                              if (response?.data?.data?.length > 0) {
                                let address: any = response.data.data[0];

                                EventService.send(
                                  `updatePointMap-miMap${indexf + 1}`,
                                  address
                                );
                              }
                            }
                          }}
                        ></ArsaForm>
                        <ArsaMap
                          extraClass="maper"
                          idMap={`miMap${indexf + 1}`}
                          icon={newlyweds}
                          onChangeCoord={(position) => {
                            let copyFormMaps: any = [...formMaps];
                            copyFormMaps[indexf][0].latLng = {
                              lat: position.lat,
                              lon: position.lon ? position.lon : position.lng,
                            };
                            setFormMaps(copyFormMaps);
                          }}
                        />

                        <div
                          className="eliminate"
                          style={{
                            marginTop: "16px",
                          }}
                        >
                          <button
                            onClick={() => {
                              let itTmp: any = [...formMaps];
                              itTmp.splice(indexf, 1);
                              setFormMaps(itTmp);
                            }}
                          >
                            Eliminar
                          </button>
                        </div>
                        <div className="line"></div>
                      </section>
                    </>
                  );
                })}
                <ArsaFabButton
                  extraClass="fabiruchis"
                  onClick={() => {
                    let itForm: any = [...formMaps];

                    itForm.push([
                      {
                        type: "text",
                        value: null,
                        label: "invite.map-where",
                        placeholder: "invite.map-where-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={textInput} alt="" />
                          </div>
                        ),
                      },
                      {
                        type: "text",
                        value: null,
                        label: "invite.map-msg",
                        placeholder: "invite.map-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={textInput} alt="" />
                          </div>
                        ),
                      },
                    ]);

                    setFormMaps(itForm);
                  }}
                >
                  <span className="material-icons">add</span>
                  {t("invite.maps")}
                </ArsaFabButton>
              </div>

              <section
                className="itinerary-form"
                style={{
                  position: "relative",
                }}
              >
                <p>{t("invite.itinerary-title")}</p>
                {itinerary.map((itineraryIteration: any, indexf: number) => {
                  return (
                    <>
                      <ArsaForm
                        data={itineraryIteration}
                        form={formRef}
                        hasAction={false}
                        buttonAction={() => {}}
                        buttonText={t("alerts.accept")}
                        extraClass={"itinerary-form-int"}
                        onChangeForm={(e, index) => {
                          let copy: any = [...itinerary];
                          switch (e.type) {
                            case "dropdown-abs":
                              let selected: any = e.values.filter(
                                (s) => s.selected
                              );
                              copy[indexf][index].value = selected[0].value;

                              let id: any = document.getElementById(
                                `common-hdr-${copy[indexf][index].id}`
                              );

                              renderRoot(
                                id.children[0],
                                <div
                                  className="drop-content-dynamic"
                                  style={{ width: "40px" }}
                                >
                                  <img
                                    style={{ width: "80%" }}
                                    src={returnIcon(Number(selected[0].value))}
                                    alt=""
                                  />
                                </div>
                              );

                              setTimeout(() => {
                                id.click();
                              }, 100);
                              break;
                          }

                          setItinerary(itinerary);
                        }}
                      >
                        <div className="eliminate">
                          <button
                            onClick={() => {
                              let itTmp: any = [...itinerary];
                              itTmp.splice(indexf, 1);
                              setItinerary(itTmp);
                            }}
                          >
                            Eliminar
                          </button>
                        </div>
                        <div className="line"></div>
                      </ArsaForm>
                    </>
                  );
                })}
                <ArsaFabButton
                  extraClass="fabiruchis"
                  onClick={() => {
                    let itForm: any = [...itinerary];
                    itForm.push([
                      {
                        type: "text",
                        value: null,
                        label: "invite.itinerary-msg",
                        placeholder: "invite.itinerary-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={textInput} alt="" />
                          </div>
                        ),
                      },
                      {
                        type: "time",
                        realType: "time",
                        value: null,
                        label: "invite.time-msg",
                        placeholder: "invite.time-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={dateInput} alt="" />
                          </div>
                        ),
                      },
                      {
                        type: "dropdown-abs",
                        value: null,
                        label: "invite.icon-msg",
                        placeholder: "invite.icon-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input-codes",
                        values: [
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(0)} alt="" />
                              </div>
                            ),
                            value: "0",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(1)} alt="" />
                              </div>
                            ),
                            value: "1",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(2)} alt="" />
                              </div>
                            ),
                            value: "2",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(3)} alt="" />
                              </div>
                            ),
                            value: "3",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(4)} alt="" />
                              </div>
                            ),
                            value: "4",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(5)} alt="" />
                              </div>
                            ),
                            value: "5",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(6)} alt="" />
                              </div>
                            ),
                            value: "6",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(7)} alt="" />
                              </div>
                            ),
                            value: "7",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(8)} alt="" />
                              </div>
                            ),
                            value: "8",
                            active: false,
                          },
                          {
                            label: (
                              <div className="drop-content1">
                                <img src={returnIcon(9)} alt="" />
                              </div>
                            ),
                            value: "9",
                            active: false,
                          },
                        ],
                        id: UtilService.getRandomNumber(10),
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={dateInput} alt="" />
                          </div>
                        ),
                      },
                    ]);
                    setItinerary(itForm);
                  }}
                >
                  <span className="material-icons">add</span>
                  {t("invite.ite")}
                </ArsaFabButton>
              </section>

              <section
                className="itinerary-form2"
                style={{
                  position: "relative",
                }}
              >
                <p>{t("invite.gif-title")}</p>
                {gifTable.map((gif: any, indexg: number) => {
                  return (
                    <ArsaForm
                      data={gif}
                      form={formRef}
                      hasAction={false}
                      buttonAction={() => {}}
                      buttonText={t("alerts.accept")}
                      extraClass={"itinerary-form-int"}
                      onChangeForm={(e, index) => {}}
                    >
                      <div
                        className="eliminate"
                        style={{
                          marginTop: "16px",
                        }}
                      >
                        <button
                          onClick={() => {
                            let itTmp: any = [...gifTable];
                            itTmp.splice(indexg, 1);
                            setGifTable(itTmp);
                          }}
                        >
                          Eliminar
                        </button>
                      </div>
                      <div className="line"></div>
                    </ArsaForm>
                  );
                })}
                <ArsaFabButton
                  extraClass="fabiruchis2"
                  onClick={() => {
                    let itForm: any = [...gifTable];
                    itForm.push([
                      {
                        type: "text",
                        value: null,
                        label: "invite.gif-msg",
                        placeholder: "invite.gif-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={textInput} alt="" />
                          </div>
                        ),
                      },
                      {
                        type: "text",
                        value: null,
                        label: "invite.gif2-msg",
                        placeholder: "invite.gif2-msg-place",
                        required: true,
                        hasTopLabel: true,
                        hasError: false,
                        error: "invite.errors.name",
                        globalExtraClass: "invite-input",
                        extraLeftComponent: (
                          <div className="custom-icon-left">
                            <img src={textInput} alt="" />
                          </div>
                        ),
                      },
                    ]);
                    setGifTable(itForm);
                  }}
                >
                  <span className="material-icons">add</span>
                  {t("invite.gefts")}
                </ArsaFabButton>
              </section>

              <section className="container-carrousel">
                <ArsaButton
                  extraClass="crud-button-carrousel"
                  onClick={() => {
                    opcionesIMG(1, true);
                  }}
                >
                  {t("invite.dd")}
                </ArsaButton>

                <section className="imgs">
                  {productImages?.map((pImg: any, i: number) => {
                    return (
                      <div className="crd">
                        <div className="img">
                          <img src={pImg} alt="" />
                        </div>

                        <div
                          className="licon"
                          onClick={() => {
                            let productImagesTmp: any = [...productImages];
                            productImagesTmp.splice(i, 1);
                            setProductImages(productImagesTmp);
                          }}
                        >
                          <span className="material-icons">delete</span>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </section>
            </ArsaForm>
          </>
        ) : (
          <ArsaSpinner height={50} name={"spinner"} />
        )}

        <div
          className="foot-btn"
          style={{
            background: theme.background,
          }}
        >
          <ArsaButton
            onClick={() => {
              //sound
              //productImages
              let jsonRequest: any = {
                principalForm: [],
                confirmForm: confirmMailForm[0].value,
                itineraryForm: [],
                formMaps: [],
                gifTable: [],
                privateForm: privateForm,
                principalImage: picture,
                sound: `data:audio/mp3;base64,${sound}`,
                carrousel: productImages,
                //id: isEdit ? data.invite.id : null
              };

              form.forEach((item: any, index: number) => {
                jsonRequest.principalForm.push({
                  index,
                  value: item.value,
                });
              });

              itinerary.forEach(
                (itemItinerary: any, indexItinerary: number) => {
                  jsonRequest.itineraryForm[indexItinerary] = [];
                  itemItinerary.forEach((element, eind) => {
                    jsonRequest.itineraryForm[indexItinerary].push({
                      index: eind,
                      value: element.value,
                    });
                  });
                }
              );

              gifTable.forEach((itemItinerary: any, indexItinerary: number) => {
                jsonRequest.gifTable[indexItinerary] = [];
                itemItinerary.forEach((element, eind) => {
                  jsonRequest.gifTable[indexItinerary].push({
                    index: eind,
                    value: element.value,
                  });
                });
              });

              formMaps.forEach((item: any, index: number) => {
                jsonRequest.formMaps[index] = [];

                item.forEach((element, eind) => {
                  let json: any = {
                    index: eind,
                    value: element.value,
                  };

                  if (eind == 0) {
                    json.latLng = element.latLng;
                  }

                  jsonRequest.formMaps[index].push(json);
                });
              });
              LoadingService.show();
              InvitePresenter.createInvite({
                request: jsonRequest,
                t,
                loadInvites,
                modal,
                isEdit: data ? data?.invite?.id : false,
                inviteId: data ? data?.invite?.id : null,
              });
            }}
          >
            {t("invite.dave")}
          </ArsaButton>
        </div>
      </ArsaContainer>

      <audio
        src={`${
          sound && (sound?.includes("base64") || sound?.includes("http"))
            ? ""
            : "data:audio/mp3;base64,"
        }${sound}`}
        id="myAudio"
        loop
      ></audio>
    </>
  );
}
