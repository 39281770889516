import CardCrudRegisters from "./pages/CardCrudRegisters/CardCrudRegisters";
import { Choosen } from "./pages/Choosen/Choosen";
import ArsaLayoutTabs from "./pages/LayoutTabs/ArsaLayoutTabs";
import { ListInvites } from "./pages/ListInvites/ListInvites";
import { Invitation } from "./pages/Invitation/Invitation";
import ArsaLogin from "./pages/Login/ArsaLogin";
import { OTP } from "./pages/OTP/OTP";
import Register from "./pages/Register/Register";
import { EventPage } from "./pages/Events/Events";
import { About } from "./pages/About/About";
import { Whatsapp } from "./pages/Whatsapp/Whatsapp";
import { Profile } from "./pages/Profile/Profile";
import { Packages } from "./pages/Packages/Packages";
export const routes = [
  {
    to: "/",
    path: "",
    Component: ArsaLogin,
    isPublic: false,
    isInit:true,
    extraData: {},
  },
  {
    to: "/home",
    path: "/home",
    Component: ArsaLayoutTabs,
    isPublic: false,
    extraData: {},
  },
  {
    to: "/login", 
    path: "/login",
    Component: ArsaLogin,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/list-catalog/:type",
    path: "/list-catalog/:type",
    Component: CardCrudRegisters,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/register/:type",
    path: "/register/:type",
    Component: Register,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/choosen",
    path: "/choosen",
    Component: Choosen,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/otp/:type/:email",
    path: "/otp/:type/:email",
    Component: OTP,
    isPublic: true,
    extraData: {},
  },
  {
    to: "/invites", 
    path: "/invites",
    Component: ListInvites,
    isPublic: false,
    extraData: {},
  },
  {
    to:"/invitation/:id",
    path: "/invitation/:id",
    Component: Invitation,
    isPublic: true,
    extraData: {},
  },
  {
    to:"/invitation/:name",
    path: "/invitation/:name",
    Component: Invitation,
    isPublic: true,
    extraData: {},
  },
  {
    to:"/events",
    path: "/events",
    Component: EventPage,
    isPublic: false,
    extraData: {},
  },
  {
    to:"/about",
    path: "/about",
    Component: About,
    isPublic: false,
    extraData: {},
  },
  {
    to:"/whatsapp",
    path: "/whatsapp",
    Component: Whatsapp,
    isPublic: false,
    extraData: {},
  },
  {
    to:"/profile",
    path: "/profile",
    Component: Profile,
    isPublic: false,
    extraData: {},
  },
  {
    to:"/packages",
    path: "/packages",
    Component: Packages,
    isPublic: false,
    extraData: {},
  },
];
