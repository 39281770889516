import { ArsaForm, ArsaContainer, useTheme } from "arsaLocalv4";
import { ArsaHeader } from "../../../../components/arsa-header/ArsaHeader";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./arsa-card-crud.scss";
import CardCrudPresenter, {
  INITIAL_CRUD_FORM,
} from "./presenter/CardCrud.presenter";

function CardCrud({
  onDismiss,
  data,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  data;
}) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [form, setForm] = useState(INITIAL_CRUD_FORM);
  const formRef = useRef(new Date().getTime());

  useEffect(() => {
    if (data.edit) {
      let formTmp: any = [...form];
      formTmp[0].value = data.catalogRegister.name;
      formTmp[1].value = data.catalogRegister.description;
      //formTmp[2].dumy_property.value = data.catalogRegister.dumy_property;
      setForm(formTmp);
    } else {
      let formTmp: any = [...form];
      formTmp[0].value = null;
      formTmp[1].value = null;
      setForm(formTmp);
    }
  }, []);

  function createOrUpdateCatalog() {
    CardCrudPresenter.createOrUpdateCatalog(
      form,
      setForm,
      t,
      data.edit,
      data?.catalogRegister?.id,
      data?.square?.props?.square ? data.square.props.square : data.square,
      onDismiss
    );
  }

  return (
    <>
      <ArsaHeader
        title={t(data.edit ? "catalogs.edit" : "catalogs.create")}
        applyPadding={true}
        isModal={true}
        closeModal={() => {
          onDismiss();
        }}
      />
      <ArsaContainer
        customId={"main-content"}
        applyPadding={true}
        extraClass={"catalogs-module"}
      >
        {data && data.square && (
          <section className="img-profile">
            <p
              style={{
                color: theme.color,
              }}
            >
              {data.edit ? t("catalogs.edit-a") : t("catalogs.create-a")}{" "}
              {data?.square?.props?.square
                ? data.square.props.square.text
                : data.square.text}
            </p>

            <div className="ctn-im">
              <div className="interior">
                <img
                  src={
                    data?.square?.props?.square
                      ? data.square.props.square.img
                      : data.square.img
                  }
                  className="img-logo"
                />
              </div>
            </div>
          </section>
        )}
        
        <ArsaForm
          data={form}
          form={formRef}
          buttonAction={() => {
            createOrUpdateCatalog();
          }}
          buttonText={t("alerts.accept")}
          extraClass={"crud-form"}
        />
      </ArsaContainer>
    </>
  );
}

export default CardCrud;
