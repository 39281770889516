import { useContext, useEffect, useRef, useState } from "react";
import "./arsa-login.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArsaLoginPresenter, {
  INITIAL_LOGIN_FORM,
} from "./presenter/ArsaLogin.presenter";
import {
  ArsaForm,
  useUserSession,
  environment,
  EventService,
  ArsaContainer,
  Context as ModalContext,
  AlertService,
} from "arsaLocalv4";
import logo from "../../assets/imgs/logos/arsaCircle.png";
import facebook from "../../assets/imgs/login/facebook.png";
import gplus from "../../assets/imgs/login/gplus.png";
import { Plugins } from "@capacitor/core";
import { FacebookAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { eventKeys, firebaseConfig } from "../../environment/environment";
import { Forgot } from "./Forgot";
import { NewPassword } from "./NewPassword";

function ArsaLogin() {
  const modal = useContext<any>(ModalContext);
  /**LOGIN EXTERN*/
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const provider = new FacebookAuthProvider();

  const { t } = useTranslation();
  const formRef = useRef(null);
  const [form, setForm] = useState(INITIAL_LOGIN_FORM);
  const [formResetPass, setFormResetPass] = useState<any>([]);
  const [email, setEmail] = useState<any>(null);
  const navigate = useNavigate();
  const { user, setEvent } = useUserSession();

  const [formForgot, setFormForgot] = useState([
    {
      type: "text",
      value: null,
      label: "login.username",
      placeholder: "login.username",
    },
  ]);

  useEffect(() => {
    EventService.send(environment.eventKeys.closeDrawer, true);

    try {
      (document.getElementById("menuApp") as any).style.display = "none";
    } catch (error) {
    }

    EventService.get(eventKeys.openNewPassModal).subscribe((data) => {
      openNewPassModal(data.email);
    });
  }, []);

  const login = () => {
    ArsaLoginPresenter.login(form, setEvent, navigate, t);
  };

  const recoveryPassword = () => {
    ArsaLoginPresenter.recoveryPassword({
      formForgot,
      setFormForgot,
      navigate,
      t,
      initTimer: null,
      setShowButton: null,
    });
  };

  function openNewPassModal(email) {
    setEmail(email);
    let idButton:any = document.getElementById("resetBtn");
    idButton.click();
  }

  function openModal(){
    modal.showModal({
      title: t("login.reset"),
      component: <NewPassword email={email} />,
      footer: false,
      extraClassContainer: "reset-modal",
      isKeepOpen: true,
      continueAction: () => {},
      buttonText: "Continuar",
    });
  }

  const forgot = () => {
    modal.showModal({
      title: t("login.forgot"),
      component: <Forgot form={formForgot} />,
      footer: true,
      extraClassContainer: "manage-modal",
      continueAction: () => {
        recoveryPassword();
        modal.closeModal();
      },
      buttonText: "Continuar",
    });
  };

  function Register() {
    navigate("/register/1");
  }

  return (
    <ArsaContainer
      customId={"main-content-login"}
      noPadding={true}
      extraClass={"login"}
    >
      <section className="container-login">
        <div className="posco-logo">{/* <img src={videoChat} /> */}</div>

        <div className="interpolate-img"></div>
        <div className="container-login-img"></div>
        <div className="white-screen"></div>

        <section className="form-box">
          <p className="title">{environment.SYSTEM_CONSTANTS.loginTemplate} </p>
          <div className="box">
            <div className="change-language">{/* <img src={mx} /> */}</div>

            <p className="login-title">{t("login.login")}</p>

            <ArsaForm
              form={formRef}
              data={form}
              buttonAction={() => {
                login();
              }}
              buttonText={t("login.login")}
              extraClass={"login-form"}
              title={""}
            />

            <p
              className="forgot"
              onClick={() => {
                forgot();
              }}
            >
              <span className="material-icons outlined">lock_open</span>
              {t("login.forgot")}
            </p>

            <p
              className="forgot"
              onClick={() => {
                Register();
              }}
            >
              <span className="material-icons outlined">app_registration</span>
              {t("login.register")}
            </p>
          </div>
        </section>
      </section>

      <div className="powered">
        <p>Powered by ©</p>
        <img src={logo} alt="Arsa Consulting Logo" />
      </div>

      <button id="resetBtn" onClick={()=>{
        openModal();
      }}>
        
      </button>
    </ArsaContainer>
  );
}

export default ArsaLogin;
