import "./style.scss";
import builder from "../../assets/imgs/choosen/builder.png";
import male from "../../assets/imgs/choosen/male.png";
import woman from "../../assets/imgs/choosen/woman.png";
import { ArsaContainer, ArsaSquare, UtilService } from "arsaLocalv4";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useNavigate } from "react-router-dom";

export function Choosen({}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [squares, setSquares] = useState([
    {
      img: male,
      text: t("choosen.boss"),
      onTap: () => {
        navigate("/register/2");
      },
      type: 1,
    },
    {
      img: builder,
      text: t("choosen.builder"),
      onTap: () => {
        navigate("/register/3");
      },
      type: 1,
    },
    {
      img: woman,
      text: t("choosen.woman"),
      onTap: () => {
        navigate("/register/4");
      },
      type: 1,
    },
  ]);

  return (
    <>
      <ArsaHeader
        title={t("choosen.title")}
        isModal={false}
        backActive={true}
        menuActive={false}
        backAction={() => {
          navigate(-1);
        }}
      />
      <ArsaContainer
        customId={"main-content-login"}
        noPadding={true}
        extraClass={"choosen"}
      >
        <section className="squares">
          {squares.map((square: any) => {
            return (
              <ArsaSquare
                square={square}
                key={`id-square-${UtilService.getRandomText(9)}`}
              />
            );
          })}
        </section>
      </ArsaContainer>
    </>
  );
}
