import {
  AlertService,
  HttpService,
  LoadingService,
  LocalStorageEncryptService,
  SqlGenericService,
  UtilService,
  environment,
  EventService
} from "arsaLocalv4";
import { verifyAccount } from "../../../email-templates/verifyAccount";
import { eventKeys } from "./../../../environment/environment";
export default class ArsaLoginPresenter {
  constructor() {}

  static login(data, setEvent, navigator, t) {
    LoadingService.show();
    try {
      let sql: string = `SELECT * FROM user WHERE password = SHA2(MD5(UNHEX(SHA2('${data[1].value}',512))),224) AND username = '${data[0].value}'`;

      SqlGenericService.excecuteQueryStringReference(sql, "login")
        .then(async (response: any) => {

          if (response.data.data.length > 0) {
            let usTmp: any = response.data.data[0];
            if(usTmp.is_active == 0){
              LoadingService.hide();
              AlertService.bottomModals(
                t("alerts.wait"),
                t("login.unverificated"),
                t("alerts.confirm"),
                () => {},
                `${"olam-alert"} ${window.innerWidth > 720 ? 'alert-desktop' : ''}`,
                false,
                false
              );
            }else{
              
            sql = `SELECT * FROM user_permissions WHERE id_user = ${usTmp.id}`;

            let permisos: any =
              await SqlGenericService.excecuteQueryStringReference(
                sql,
                "getPermissions"
              );
            LoadingService.hide();

            usTmp.permisos = permisos.data.data[0];
            delete usTmp.password;
            //localStorage.setItem("merryMeSession", JSON.stringify(usTmp));
            LocalStorageEncryptService.setToLocalStorage(
              environment.userTagSession,
              usTmp
            );
            setEvent(usTmp);
            navigator("/home");
            }
          } else {
            LoadingService.hide();
            AlertService.bottomModals(
              t("alerts.wait"),
              t("login.errpass"),
              t("alerts.confirm"),
              () => {},
              `${"olam-alert"} ${window.innerWidth > 720 ? 'alert-desktop' : ''}`,
              false,
              false
            );
          }
        })
        .catch((error) => {
          AlertService.bottomModals(
            t("alerts.wait"),
            t("login.errpass"),
            t("alerts.confirm"),
            () => {},
            `${"olam-alert"} ${window.innerWidth > 720 ? 'alert-desktop' : ''}`,
            false,
            false
          );
          LoadingService.hide();
        });
    } catch (error) {
      LoadingService.hide();
    }
  }

  static async recoveryPassword({
    formForgot,
    setFormForgot,
    navigate,
    t,
    initTimer,
    setShowButton,
  }) {
    LoadingService.show();
    let otp: any = UtilService?.getRandomNumber(6);
    let sqlCheckUser: string = `SELECT * FROM user WHERE username = '${formForgot[0].value}'`;
    let responseCheckUser: any =
      await SqlGenericService.excecuteQueryStringReference(
        sqlCheckUser,
        "checkUser"
      );

    let user = (
      responseCheckUser.data.data
        ? responseCheckUser.data.data
        : responseCheckUser.data
    )[0];

    if (user) {
      let sqlUpdate: string = `UPDATE user SET otp = '${otp}' WHERE email = '${formForgot[0].value}'`;
      SqlGenericService.excecuteQueryStringReference(sqlUpdate, "updateOTP")
        .then((response: any) => {
          let email: string = verifyAccount;
          email = email.replace("[name]", user.name);
          email = email.replace("[otp]", otp);
          email = email.replace(
            "[link]",
            `http://www.ar-sa.com.mx/marryme/otp/${formForgot[0].value}`
          );

          let request: any = {
            asunto: "MarryMeApp",
            from: "contacto@olam-systems.com.mx",
            name: user.name,
            to: formForgot[0].value,
            cuerpo: email,
          };

          HttpService.post(`${environment.environmentData.sendEmail}`, request)
            .then((responseFile: any) => {
              LoadingService.hide();
              if (initTimer) {
                setShowButton(false);
                setTimeout(() => {
                  initTimer();
                }, 1000);
              } else {
                navigate(`/otp/0/${formForgot[0].value}`);
              }
            })
            .catch((e) => {
              LoadingService.hide();
              AlertService.bottomModals(
                t("alerts.error"),
                t("alerts.embarrassing")
              );
            });
        })
        .catch(() => {
          LoadingService.hide();
          AlertService.bottomModals(
            t("alerts.error"),
            t("alerts.embarrassing")
          );
        });
    } else {
      LoadingService.hide();
      //here lert user no exists
      AlertService.bottomModals(t("alerts.error"), t("login.exists"));
    }
  }

  static async resetOTP(email) {
    let sqlUpdate: string = `UPDATE user SET otp = '-' WHERE email = '${email}'`;
    await SqlGenericService.excecuteQueryStringReference(
      sqlUpdate,
      "updateOTP"
    );
  }

  static async validateOTP(email, otp, type, navigate, t) {
    let sqlUpdate: string = `SELECT * FROM user WHERE email = '${email}' AND otp = '${otp}'`;
    let response: any = await SqlGenericService.excecuteQueryStringReference(
      sqlUpdate,
      "validateOTP"
    );
    if(response.status && response.status == 200){
      let activeUser: string = `UPDATE user SET is_active = 1 WHERE email = '${email}'`;
      await SqlGenericService.excecuteQueryStringReference(
        activeUser,
        "updateUser"
      );

      if(type == 0){
        navigate("/login");
        setTimeout(() => {
          EventService.send(eventKeys.openNewPassModal,{
            email: email
          });
        }, 2000);
      }else{
        let tmpPass:any = LocalStorageEncryptService.getFromLocalStorage("tmpPass");
        let data:any = [
          {
            value: email
          },
          {
            value: tmpPass
          }
        ];
        this.login(data,null,navigate,t);
      }

    }
  }
}

export const INITIAL_LOGIN_FORM = [
  {
    type: "text",
    value: null,
    label: "login.username",
    placeholder: "login.username",
  },
  {
    type: "password",
    id: "pwdlogin",
    value: null,
    label: "login.password",
    placeholder: "login.password",
    hasEye: true,
    extraClass: "extraEye",
  },
];
