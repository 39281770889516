export let verifyAccount = `<section style="display: flex;
align-items: start; font-family:Ubuntu;
justify-content: center;">
<div style="width: 600px;height:1860px; background-color:#262333">
<div style="border-bottom: 1px solid #F7F8F2;
    padding-bottom: 35px;
    padding-top: 30px;
    width: 100%;
    text-align: center;">

<p style="font-size:34px; color:#FFF">
  Marry Me?
</p>
</div>

<div style="  margin-top: 110px;width:100%; text-align:center">
<img src="https://weltio-images.s3.us-east-2.amazonaws.com/weltio-email/emailImage.png" alt="" style="width:80%">
</div>

<div style="margin-top: 0;
    padding: 0 55px;
      padding-bottom: 0px;
    border-bottom: 1px solid #F7F8F2;
    padding-bottom: 109px;
    ">
<p style="margin: 0;
      color: #F7F8F2;
      font-style: normal;
      font-weight: 400;
      font-size: 52px;
      line-height: 129.1%;
      text-align: center;">Verifica tu cuenta de
  correo electrónico
  MarryMe</p>
</div>

<div style="padding: 0 63px;
    padding-top: 0px;
  padding-top: 109px; text-align: center;border-bottom: 1px solid #F7F8F2;
    padding-bottom: 124px;">
<p style="margin: 0;
      color: #EFF1E5;
      font-style: normal;
      font-weight: 400;
      font-size: 37px;
      line-height: 129.1%;
      text-align: center;">Hola [name]</p>
<p style="color: #F7F8F2;
      font-style: normal;
      font-weight: 300;
      font-size: 37px;
      line-height: 129.1%;
      text-align: center;
      margin-top: 10px;
      ">Para poder verificar tu correo electrónico ingresa este código en el formulario OTP de tu aplicación</p>

<button style="background: #6D70F6;
        border-radius: 40px;font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 129.1%;color: #FFFFFF; outline:none;width: 100%;
padding: 12px;margin-top: 96px;border:none">
  <a style="color: #FFF;
        text-decoration: none;">
    Codigo: [otp]
  </a></button>
</div>


<div style="margin-top: 96px;
    padding: 0 57px;display: inline-block; vertical-align: middle;">
<p style="color: #F7F8F2;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 80.1%;margin:0">Atentamente</p>
<p style="color: #F7F8F2;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 80.1%;margin:0;margin-top: 14px;">El equipo de minviTe</p>
</div>

</div>
</section>`;
