import {
  AlertService,
  HttpService,
  LoadingService,
  LocalStorageEncryptService,
  SqlGenericService,
  TableTemplate,
  UtilService,
  environment,
} from "arsaLocalv4";
import code1 from "../../../assets/imgs/invites/code1.png";
import code2 from "../../../assets/imgs/invites/code2.png";
import code3 from "../../../assets/imgs/invites/code3.png";
import code4 from "../../../assets/imgs/invites/code4.png";
import code5 from "../../../assets/imgs/invites/code5.png";
import code6 from "../../../assets/imgs/invites/code6.png";
import colorInput from "../../../assets/svg/colorInput.svg";
import dateInput from "../../../assets/svg/dateInput.svg";
import textInput from "../../../assets/svg/textInput.svg";

export class InvitePresenter {
  static async getLatLng(value, signal) {
    let response: any = await HttpService.get(
      `https://www.olam-systems.com.mx/olam/utils/getFromStreet?street=${value}`,
      {},
      signal
    );
    return response;
  }

  static async createInvite({
    request,
    isEdit = false,
    inviteId = null,
    t,
    loadInvites = () => {},
    modal,
  }) {
    try {
      let user = LocalStorageEncryptService.getFromLocalStorage(
        environment.userTagSession
      );
      let dt: any = new Date().toISOString().split("T")[0].split("-");

      LoadingService.show();
      let copy: any = { ...request };
      delete copy.principalImage;
      delete copy.sound;
      delete copy.carrousel;
      let sqlInsertInvite: string = isEdit
        ? `UPDATE invites SET 
        json_invite = '${JSON.stringify(copy)}',
        name = '${request.principalForm[2].value ? request.principalForm[2].value.toUpperCase().replace(/ /g, "_") : '-'}',
        is_public_event = ${request.privateForm[0].value ? 0 : 1},
        update_date =  NOW() WHERE id = ${inviteId}
        `
        : `INSERT INTO invites (
        id_user,
        is_public_event,
        json_invite,
        date_at,
        invite_code,
        is_editable,
        expire_date,
        name
      ) VALUES (
        ${user.id},
        ${request.privateForm[0].value ? 0 : 1}
        '${JSON.stringify(copy)}',
        NOW(),
        '${UtilService.getRandomText(20)}',
        1,
        '${
          request.principalForm &&
          request.principalForm[4] &&
          request.principalForm[4].value
            ? request.principalForm[4].value
            : dt
        } 23:59:59',
        '${request.principalForm[2].value ? request.principalForm[2].value.toUpperCase().replace(/ /g, "_") : '-'}'
      )`;
      SqlGenericService.excecuteQueryStringReference(
        sqlInsertInvite,
        isEdit ? "updateInvite" : "createInvite"
      )
        .then(async (response: any) => {
          if (isEdit) {
            let sqlDeleteImages: string = `DELETE FROM invite_image WHERE id_invite = ${inviteId}`;
            let deleteResponse: any =
              await SqlGenericService.excecuteQueryStringReference(
                sqlDeleteImages,
                "deleteImages"
              );
          }

          let insertId = isEdit ? inviteId : response.data.data;

          let requestFile: any = {
            b64: request.principalImage,
            id: insertId, //usuario de nueva creacion
            extension: "png",
            table: "invites",
          };

          for (const iteratorImage of request.carrousel) {
            if (!iteratorImage.includes("http")) {
              let sqlInsertInd: string = `INSERT INTO invite_image (id_invite) VALUES (${insertId})`;

              let insertResponse: any =
                await SqlGenericService.excecuteQueryStringReference(
                  sqlInsertInd,
                  "insertCarrousel"
                );

              let insertIntoCarrousel: any = insertResponse.data.data;
              let requestFileForIterate: any = {
                b64: iteratorImage,
                id: insertIntoCarrousel, //usuario de nueva creacion
                extension: "png",
                table: "invite_image",
              };
              await HttpService.post(
                `${environment.environmentData.loadFileOnly}/${insertIntoCarrousel}`,
                requestFileForIterate
              );
            }
          }

          if (
            request.principalImage &&
            request.principalImage.length > 0 &&
            !request.principalImage.includes("http")
          ) {
            await HttpService.post(
              `${environment.environmentData.loadFileOnly}/${insertId}`,
              requestFile
            );

            let sqlUpdateWithImage: string = ``;
          }

          if (
            request.sound &&
            request.sound.length > 0 &&
            !request.sound.includes("http")
          ) {
            let requestSoundFile: any = {
              b64: request.sound,
              id: insertId, //usuario de nueva creacion
              extension: "mp3",
              table: "invites",
              field: "url_sound",
            };

            await HttpService.post(
              `${environment.environmentData.loadFileOnly}/${insertId}`,
              requestSoundFile
            );
          }

          LoadingService.hide();
          AlertService.bottomModals(
            t(`invite.success${isEdit ? "-edit" : ""}`),
            t("invite.can")
          );
          modal.closeModal();
          loadInvites();
        })
        .catch(() => {
          LoadingService.hide();
          AlertService.bottomModals(
            t("alerts.error"),
            t("alerts.embarrassing")
          );
        });
    } catch (error) {
      LoadingService.hide();
    }
  }

  static async getAllInvites(
    setLoad,
    setMyInvites,
    setTable,
    t,
    limit,
    paginatorOriginal,
    setPaginator,
    first: boolean = false,
    openInvite,
    theme,
    navigate
  ) {
    try {
      let paginator: any = { ...paginatorOriginal };
      const user = LocalStorageEncryptService.getFromLocalStorage(
        environment.userTagSession
      );
      let sqlGetInvites: string = `select GROUP_CONCAT(im.url_img SEPARATOR '||') AS image_paths, i.*
      from invites i 
      left JOIN invite_image im
      on(im.id_invite = i.id)
      where i.id_user = ${user.id} GROUP BY i.id`;

      let sqlCount: string = `SELECT count(*) as total from invites i
      where i.id_user = ${user.id}`;

      sqlGetInvites += ` LIMIT ${limit} OFFSET ${
        limit * (paginator.actual == 1 ? 0 : paginator.actual - 1)
      }`;

      if (!first) {
        let result: any = await SqlGenericService.excecuteQueryStringReference(
          sqlCount,
          "getAllInvites"
        );
        paginator.maximum = Math.ceil(
          Number(result.data.data[0].total) / limit
        );

        if (paginator.maximum > 2) {
          paginator.actual = 1;
          paginator.prev = 1;
          paginator.next = 2;
          paginator.afterNext = 3;
        } else if (paginator.maximum < 3) {
          paginator.actual = 1;
          paginator.prev = 1;
          paginator.next = 2;
          paginator.afterNext = 2;
        } else {
          paginator.actual = 1;
          paginator.prev = 1;
          paginator.next = 1;
          paginator.afterNext = 1;
        }
      }

      if (first) {
        LoadingService.show();
      }

      let response: any = await SqlGenericService.excecuteQueryStringReference(
        sqlGetInvites,
        "getInvites"
      );
      let responseTmp: any = response.data.data;
      let tableData: TableTemplate = {
        headers: [
          {
            title: t("invite.invite-prncl"),
            width: 160,
          },
          {
            title: t("invite.invite-title"),
          },
          {
            title: t("invite.invite-code"),
          },
          {
            title: t("invite.invite-at"),
          },
          { title: t("invite.invite-actions"), width: 150 },
        ],
        content: [],
        extraClass: "invite-table-data",
      };

      responseTmp.forEach((itm: any) => {
        itm.json_invite = JSON.parse(itm.json_invite);

        tableData.content.push({
          label: "",
          components: [
            <div className="img-prncl">
              <img
                src={itm.url_img}
                alt={itm.json_invite?.principalForm[0]?.value}
              />
            </div>,
            <div>
              {" "}
              {itm.json_invite?.principalForm[0]?.value
                ? itm.json_invite?.principalForm[0]?.value
                : "S/N"}{" "}
            </div>,
            <div> {itm.invite_code} </div>,
            <div> {itm.date_at} </div>,
            <div className="actions">
              <div
              style={{
                backgroundColor: theme.component,
              }}
                onClick={() => {
                  openInvite(itm, true);
                }}
              >
                <span className="material-icons">edit</span>
              </div>
              <div
              style={{
                backgroundColor: theme.component,
              }}
                onClick={async () => {
                  await InvitePresenter.deleteInvite(itm, setMyInvites);
                  this.getAllInvites(
                    setLoad,
                    setMyInvites,
                    setTable,
                    t,
                    limit,
                    paginatorOriginal,
                    setPaginator,
                    first,
                    openInvite,
                    theme,
                    navigate
                  );
                }}
              >
                <span className="material-icons">delete</span>
              </div>

              <div
              style={{
                backgroundColor: theme.component,
              }}
                onClick={() => {
                  navigate(`/invitation/${itm.id}`);
                }}
              >
                <span className="material-icons">visibility</span>
              </div>
            </div>,
          ],
        });

      });

      setPaginator(paginator);
      setTable(tableData);
      setMyInvites(responseTmp);
    } catch (error) {
      console.log(error);
    } finally {
      if (first) {
        LoadingService.hide();
      }
    }
  }

  static async deleteInvite(invitation, setMyInvites) {
    try {
      LoadingService.show();
      const user = LocalStorageEncryptService.getFromLocalStorage(
        environment.userTagSession
      );

      let sqlDelete: string = `DELETE FROM invites WHERE id = ${invitation.id}`;

      let responseDelete: any =
        await SqlGenericService.excecuteQueryStringReference(
          sqlDelete,
          `deleteInvite-${invitation.id}`
        );

      let sqlGetInvites: string = `select GROUP_CONCAT(im.url_img SEPARATOR '||') AS image_paths, i.*
      from invites i 
      left JOIN invite_image im
      on(im.id_invite = i.id)
      where i.id_user = ${user.id} GROUP BY i.id`;
      let response: any = await SqlGenericService.excecuteQueryStringReference(
        sqlGetInvites,
        "getInivites"
      );
      let responseTmp: any = response.data.data;
      responseTmp.forEach((itm: any) => {
        itm.json_invite = JSON.parse(itm.json_invite);
      });

      setMyInvites(responseTmp);
    } catch (error) {
    } finally {
      LoadingService.hide();
    }
  }
}

export const INITIAL_CRUD_FORM_INVITE = [
  {
    type: "text",
    value: null,
    label: "invite.title",
    placeholder: "invite.title-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "dropdown-abs",
    value: null,
    label: "invite.letter1",
    placeholder: "invite.title-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    values: [],
    id: "letters1",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "text",
    value: null,
    label: "invite.who",
    placeholder: "invite.who-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "dropdown-abs",
    value: null,
    label: "invite.letter2",
    placeholder: "invite.title-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    values: [],
    id: "letters2",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "date",
    realType: "date",
    value: null,
    label: "invite.when",
    placeholder: "invite.when-place",
    required: true,
    hasTopLabel: true,
    id: "ledate",
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={dateInput} alt="" />
      </div>
    ),
  },
  {
    type: "text",
    value: null,
    label: "invite.female-fhater",
    placeholder: "invite.female-fhater-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "text",
    value: null,
    label: "invite.female-mother",
    placeholder: "invite.female-mother-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },

  {
    type: "text",
    value: null,
    label: "invite.male-fhater",
    placeholder: "invite.male-fhater-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "text",
    value: null,
    label: "invite.male-mother",
    placeholder: "invite.male-mother-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },

  {
    type: "dropdown-abs",
    value: null,
    label: "invite.letter3",
    placeholder: "invite.title-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    values: [],
    id: "letters3",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },

  {
    type: "dropdown-abs",
    value: null,
    label: "invite.dress-msg",
    placeholder: "invite.dress-msg-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input-codes",
    values: [
      {
        label: (
          <div className="drop-content1">
            <img src={code1} alt="" />
          </div>
        ),
        value: "1",
        active: false,
      },
      {
        label: (
          <div className="drop-content1">
            <img src={code2} alt="" />
          </div>
        ),
        value: "2",
        active: false,
      },
      {
        label: (
          <div className="drop-content1">
            <img src={code3} alt="" />
          </div>
        ),
        value: "3",
        active: false,
      },
      {
        label: (
          <div className="drop-content1">
            <img src={code4} alt="" />
          </div>
        ),
        value: "4",
        active: false,
      },
      {
        label: (
          <div className="drop-content1">
            <img src={code5} alt="" />
          </div>
        ),
        value: "5",
        active: false,
      },
      {
        label: (
          <div className="drop-content1">
            <img src={code6} alt="" />
          </div>
        ),
        value: "6",
        active: false,
      },
    ],
    id: "codeId",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={dateInput} alt="" />
      </div>
    ),
  },
  {
    type: "dropdown-abs",
    value: null,
    label: "invite.invite-msg",
    placeholder: "invite.invite-msg-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input-codes",
    values: [],
    id: "typeId",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={dateInput} alt="" />
      </div>
    ),
  },
  {
    type: "color",
    value: null,
    label: "invite.color1",
    placeholder: "invite.color1",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={colorInput} alt="" />
      </div>
    ),
  },
  {
    type: "color",
    value: null,
    label: "invite.color2",
    placeholder: "invite.color2",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={colorInput} alt="" />
      </div>
    ),
  },
];

export const CONFIRM_MAIL = [
  {
    type: "text",
    value: null,
    label: "invite.mail",
    placeholder: "invite.mail-place",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
];

export const PERMISSIONS_INVITE = [
  {
    type: "switch",
    value: true,
    realValue: 0,
    label: "invite.isprivate",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "is-private-input",
  },
];