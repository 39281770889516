import {
  AlertService,
  LoadingService,
  SqlGenericService,
  environment,
  UtilService,
  HttpService,
  LocalStorageEncryptService,
} from "arsaLocalv4";
import { customerType } from "../../../environment/environment";
import { FcmService } from "../../../services/FCM.service";

export default class RegisterPresenter {
  static validateFirstStep(
    formStep: any,
    setFormStep,
    isBlur: boolean = false,
    t
  ) {
    let errors: number = 0;

    formStep.forEach((itm: any, index: number) => {
      if (
        (index < formStep.length - 2 && itm.value?.length <= 0) ||
        (index < formStep.length - 1 && !itm.value)
      ) {
        errors++;
      }
    });

    if (isBlur) {
      if (formStep[4].value != formStep[5].value) {
        formStep[5].hasError = true;
        formStep[5].errorMessage = `${t("alerts.passwords")}`;
        errors++;
      }
    }

    let newForm = [...formStep];
    //newForm[position][3].disabled = errors > 0;
    setFormStep(newForm);
  }

  static async sendForm(realForm, setRegisterForm, t, i18n, picture, navigate) {
    try {
      let form = [...realForm];
      let regexMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let errors: any = [];

      form.forEach((item: any) => {
        if (item.required && (!item.value || item?.value.length == 0)) {
          errors.push(
            `${t("alerts.field")}${t(item.placeholder)}${t("alerts.required")}`
          );
          item.hasError = true;
          item.errorMessage = `${t("alerts.field")}${t(item.placeholder)}${t(
            "alerts.required"
          )}`;
        } else {
          item.hasError = false;
          item.errorMessage = ``;
        }
      });
      if (!regexMail.test(form[3].value)) {
        errors.push(
          `${t("alerts.field")}${t(form[3].placeholder)}${t("alerts.invalid")}`
        );
        form[3].hasError = true;
        form[3].errorMessage = `${t("alerts.field")}${t(
          form[3].placeholder
        )}${t("alerts.invalid")}`;
      } else {
        form[3].hasError = false;
        form[3].errorMessage = ``;
      }

      if (form[4].value != form[5].value) {
        errors.push(`${t("alerts.passwords")}`);
        form[5].hasError = true;
        form[5].errorMessage = `${t("alerts.passwords")}`;
      } else {
        form[5].hasError = false;
        form[5].errorMessage = ``;
      }
      if (errors.length > 0) {
        setRegisterForm(form);
        return;
      }
      LoadingService.show();
      let otp: any = UtilService?.getRandomNumber(6);

      let sqlCheckUser: string = `SELECT * FROM user WHERE username = '${form[3].value}'`;
      let responseCheckUser: any =
        await SqlGenericService.excecuteQueryStringReference(
          sqlCheckUser,
          "checkUser"
        );

      if (
        responseCheckUser &&
        (responseCheckUser.data.data
          ? responseCheckUser.data.data
          : responseCheckUser.data
        ).length <= 0
      ) {
        let sqlInsert: string = `INSERT INTO user (
          id_user_type,
          name,
          last_name, 
          language,
          last_session,
          password,
          username,
          second_last_name,
          is_active,
          email,
          height,
          width, otp)
          VALUES (
            ${customerType},
            '${form[0].value}',
            '${form[1].value}',
            '${i18n.language}',
            now(),
            SHA2(MD5(UNHEX(SHA2('${form[4].value}',512))),224),
            '${form[3].value}',
            '${form[2].value}',
            0,
            '${form[3].value}',
            '${window.innerHeight}',
            '${window.innerWidth}', '${otp}')
        `;
        SqlGenericService.excecuteQueryStringReference(
          sqlInsert,
          "registerUser"
        ).then(
          (response: any) => {
            let insertId = response.data.data
              ? response.data.data
              : response.data;
            LocalStorageEncryptService.setToLocalStorage(
              "tmpPass",
              form[4].value
            );
            let requestFile: any = {
              b64: picture,
              id: insertId, //usuario de nueva creacion
              extension: "png",
              table: "user",
            };

            if (picture) {
              HttpService.post(
                `${environment.environmentData.loadFileOnly}/${insertId}`,
                requestFile
              ).then(
                (responseFile: any) => {
                  this.grants(insertId);
                  AlertService.bottomModals(
                    t("alerts.gud"),
                    t("register.success"),
                    t("alerts.confirm"),
                    () => {},
                    "olam-alert",
                    false,
                    false
                  );
                  navigate(`/otp/1/${form[3].value}`);
                },
                (err: any) => {
                  AlertService.bottomModals(
                    t("alerts.gud"),
                    t("register.successbut"),
                    t("alerts.confirm"),
                    () => {},
                    "olam-alert",
                    false,
                    false
                  );
                  LoadingService.hide();
                }
              );
            } else {
              this.grants(insertId);
              AlertService.bottomModals(
                t("alerts.gud"),
                t("register.success"),
                t("alerts.confirm"),
                () => {},
                "olam-alert",
                false,
                false
              );
              navigate(`/otp/1/${form[3].value}`);
              //this.autologin(form, navigate, t);
            }

            LoadingService.hide();
          },
          (error: any) => {
            LoadingService.hide();
            AlertService.bottomModals(
              t("alerts.error"),
              t("alerts.embarrassing")
            );
          }
        );
      } else {
        LoadingService.hide();
        AlertService.bottomModals(
          t("alerts.gud"),
          t("alerts.exists"),
          t("alerts.confirm"),
          () => {},
          "olam-alert",
          false,
          false
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async grants(insertId) {
    let sqlPermission: string = `INSERT INTO user_permissions (id_user, reports, catalogs) VALUES (${insertId},1,1)`;
    await SqlGenericService.excecuteQueryStringReference(
      sqlPermission,
      "setPermissions"
    );
  }

  static async autologin(form, navigate, t) {
    let sql: string = `SELECT * FROM user WHERE password = SHA2(MD5(UNHEX(SHA2('${form[4].value}',512))),224) AND username = '${form[3].value}'`;
    SqlGenericService.excecuteQueryStringReference(sql, "login").then(
      async (response: any) => {
        if (response.data.data.length > 0) {
          let usTmp: any = response.data.data[0];
          sql = `SELECT * FROM user_permissions WHERE id_user = ${usTmp.id}`;

          let permisos: any =
            await SqlGenericService.excecuteQueryStringReference(
              sql,
              "getPermissions"
            );
          LoadingService.hide();

          usTmp.permisos = permisos.data.data[0];
          delete usTmp.password;
          localStorage.setItem(
            environment.userTagSession,
            JSON.stringify(usTmp)
          );
          FcmService.updateTokenUser();
          navigate("/home");
        } else {
          LoadingService.hide();
          AlertService.bottomModals("Oops", t("login.password"));
        }
      }
    );
  }

  static async resetPassword(email, password, navigate, t) {
    LoadingService.show();
    let sqlUpdatePassword: string = `UPDATE user SET password = SHA2(MD5(UNHEX(SHA2('${password}',512))),224) WHERE email = '${email}'`;
    SqlGenericService.excecuteQueryStringReference(
      sqlUpdatePassword,
      "resetPassword"
    ).then(() => {
      let form: any = [{}, {}, {}, { value: email }, { value: password }];
      this.autologin(form, navigate, t);
    });
  }
}

export const registerForm = [
  {
    type: "text",
    value: null,
    label: "register.name",
    placeholder: "register.name",
    maxLength: 100,
    required: true,
  },
  {
    type: "text",
    value: null,
    label: "register.last-name",
    placeholder: "register.last-name",
    maxLength: 200,
    required: true,
  },
  {
    type: "text",
    value: null,
    label: "register.second-name",
    placeholder: "register.second-name",
    maxLength: 200,
    required: false,
  },
  {
    type: "text",
    value: null,
    label: "register.email",
    placeholder: "register.email",
    maxLength: 200,
    required: true,
  },
  {
    type: "password",
    value: null,
    label: "register.password",
    placeholder: "register.password",
    maxLength: 200,
    required: true,
    regexes: [
      {
        regex: /^.{9,}$/,
        message: "alerts.regexes.r1",
        isValid: false,
      },
      {
        regex: /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]).*$/,
        message: "alerts.regexes.r2",
        isValid: false,
      },
      {
        regex: /^(?=.*\d).*$/,
        message: "alerts.regexes.r3",
        isValid: false,
      },
      {
        regex: /^(?=.*[A-Z]).*$/,
        message: "alerts.regexes.r4",
        isValid: false,
      },
      {
        regex: /^(?=.*[a-z]).*$/,
        message: "alerts.regexes.r5",
        isValid: false,
      },
    ],
    hasEye: true,
    extraClass: "extraEye",
  },
  {
    type: "password",
    value: null,
    label: "register.password-confirm",
    placeholder: "register.password-confirm",
    maxLength: 200,
    required: true,
    hasEye: true,
    extraClass: "extraEye",
  },
];

export const registerNewPassForm = [
  {
    type: "password",
    value: null,
    label: "register.password",
    placeholder: "register.password",
    maxLength: 200,
    required: true,
    regexes: [
      {
        regex: /^.{9,}$/,
        message: "alerts.regexes.r1",
        isValid: false,
      },
      {
        regex: /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-]).*$/,
        message: "alerts.regexes.r2",
        isValid: false,
      },
      {
        regex: /^(?=.*\d).*$/,
        message: "alerts.regexes.r3",
        isValid: false,
      },
      {
        regex: /^(?=.*[A-Z]).*$/,
        message: "alerts.regexes.r4",
        isValid: false,
      },
      {
        regex: /^(?=.*[a-z]).*$/,
        message: "alerts.regexes.r5",
        isValid: false,
      },
    ],
    hasEye: true,
    extraClass: "extraEye",
  },
  {
    type: "password",
    value: null,
    label: "register.password-confirm",
    placeholder: "register.password-confirm",
    maxLength: 200,
    required: true,
    hasEye: true,
    extraClass: "extraEye",
  },
];
