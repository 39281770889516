import { AlertService, EventService, SqlGenericService } from "arsaLocalv4";
import textInput from "../../../assets/svg/textInput.svg";
export class InvitationPresenter {
  public static async getInvitation(
    id,
    name,
    setInvite,
    setPhrase,
    openInvite,
    t
  ) {
    try {

      let sqlGetInvites: string = `select GROUP_CONCAT(im.url_img SEPARATOR '||') AS image_paths, i.*
      from invites i 
      left JOIN invite_image im
      on(im.id_invite = i.id)
      where i.id = '${id}' OR i.name = '${id}'`;
      let response: any = await SqlGenericService.excecuteQueryStringReference(
        sqlGetInvites,
        "getInvite"
      );

      let sqlPhrase: string =
        "SELECT * FROM invite_phrase ORDER BY create_at DESC LIMIT 1";

      let responsePhrase: any =
        await SqlGenericService.excecuteQueryStringReference(
          sqlPhrase,
          "getPhrase"
        );

      setPhrase(responsePhrase.data.data[0]);

      if (response?.data?.data[0]) {
        let invite: any = response.data.data[0];
        invite.json = JSON.parse(invite.json_invite);

        if (invite.json.formMaps) {
          setTimeout(() => {
            invite.json.formMaps.forEach((map, index) => {
              try {
                let mapita: any = {};

                mapita.latLng = {
                  lat: map[0].latLng?.lat,
                  lon: map[0].latLng.lon ? map[0].latLng.lon : map[0].latLng.lng,
                };

                setTimeout(() => {
                  EventService.send(
                    `updatePointMap-miMap${index + 1}`,
                    mapita.latLng
                  );
                  setTimeout(() => {
                    EventService.send(
                      `updatePointMap-miMap${index + 1}`,
                      mapita.latLng
                    );
                  }, 1500);
                }, 1500);
              } catch (error) {
                console.log(error);
              }
            });
          }, 1000);
        }

        setInvite(invite);

        setTimeout(() => {
          openInvite();
        }, 100);
      } else {
        AlertService.bottomModals(
          t("alerts.error"),
          t("alerts.embarrassing")
        );
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export const CONFIRM_FORM = [
  {
    type: "text",
    value: null,
    label: "invite.subject",
    placeholder: "invite.subject",
    id:"subjectf",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "switch",
    value: true,
    realValue: 0,
    id:"asistence",
    label: "invite.asis",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
  },
  {
    type: "textarea",
    value: null,
    label: "invite.mgh",
    placeholder: "invite.mgh",
    required: true,
    id:"subjectf2",
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
];

