import {
  AlertService,
  LoadingService,
  SqlGenericService,
  environment,
  UtilService,
  HttpService,
  LocalStorageEncryptService,
} from "arsaLocalv4";
import { customerType } from "../../../environment/environment";
import { FcmService } from "../../../services/FCM.service";

export default class ProfilePresenter {
  static async sendForm(
    realForm,
    setRegisterForm,
    t,
    i18n,
    picture,
    navigate,
    user,
    modal
  ) {
    try {
      let form = [...realForm];
      let regexMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let errors: any = [];

      form.forEach((item: any) => {
        if (item.required && (!item.value || item?.value.length == 0)) {
          errors.push(
            `${t("alerts.field")}${t(item.placeholder)}${t("alerts.required")}`
          );
          item.hasError = true;
          item.errorMessage = `${t("alerts.field")}${t(item.placeholder)}${t(
            "alerts.required"
          )}`;
        } else {
          item.hasError = false;
          item.errorMessage = ``;
        }
      });
      if (!regexMail.test(form[3].value)) {
        errors.push(
          `${t("alerts.field")}${t(form[3].placeholder)}${t("alerts.invalid")}`
        );
        form[3].hasError = true;
        form[3].errorMessage = `${t("alerts.field")}${t(
          form[3].placeholder
        )}${t("alerts.invalid")}`;
      } else {
        form[3].hasError = false;
        form[3].errorMessage = ``;
      }

      if (errors.length > 0) {
        setRegisterForm(form);
        return;
      }
      LoadingService.show();
      let otp: any = UtilService?.getRandomNumber(6);

      let sqlCheckUser: string = `SELECT * FROM user WHERE username = '${form[3].value}' AND id != ${user.id}`;
      let responseCheckUser: any =
        await SqlGenericService.excecuteQueryStringReference(
          sqlCheckUser,
          "checkUser"
        );

      if (
        responseCheckUser &&
        (responseCheckUser.data.data
          ? responseCheckUser.data.data
          : responseCheckUser.data
        ).length <= 0
      ) {
        let sqlInsert: string = `UPDATE user SET
          name = '${form[0].value}',
          last_name = '${form[1].value}', 
          second_last_name = '${form[2].value}',
          email = '${form[3].value}',
          phone = '${form[4].value}' WHERE id = ${user.id}
        `;
        SqlGenericService.excecuteQueryStringReference(
          sqlInsert,
          "updateUser"
        ).then(
          (response: any) => {
            let usTmp: any = { ...user };
            let insertId = user.id;
            let requestFile: any = {
              b64: picture,
              id: insertId, //usuario de nueva creacion
              extension: "png",
              table: "user",
            };

            if (picture) {
              HttpService.post(
                `${environment.environmentData.loadFileOnly}/${insertId}`,
                requestFile
              ).then(
                (responseFile: any) => {
                  usTmp.name = form[0].value;
                  usTmp.last_name = form[1].value;
                  usTmp.second_last_name = form[2].value;
                  usTmp.email = form[3].value;
                  usTmp.phone = form[4].value;
                  usTmp.url_img = responseFile.data.data
                    ? responseFile.data.data
                    : responseFile.data;
                  LocalStorageEncryptService.setToLocalStorage(
                    environment.userTagSession,
                    usTmp
                  );
                  modal.closeModal();
                  AlertService.bottomModals(
                    t("alerts.gud"),
                    t("register.success2"),
                    t("alerts.confirm"),
                    () => {},
                    "olam-alert one-button",
                    false,
                    false
                  );
                },
                (err: any) => {
                  AlertService.bottomModals(
                    t("alerts.gud"),
                    t("register.successbut2"),
                    t("alerts.confirm"),
                    () => {},
                    "olam-alert one-button",
                    false,
                    false
                  );
                  LoadingService.hide();
                }
              );
            } else {
              usTmp.name = form[0].value;
              usTmp.last_name = form[1].value;
              usTmp.second_last_name = form[2].value;
              usTmp.email = form[3].value;
              usTmp.phone = form[4].value;
              LocalStorageEncryptService.setToLocalStorage(
                environment.userTagSession,
                usTmp
              );
              modal.closeModal();
              AlertService.bottomModals(
                t("alerts.gud"),
                t("register.success2"),
                t("alerts.confirm"),
                () => {},
                "olam-alert one-button",
                false,
                false
              );
            }

            LoadingService.hide();
          },
          (error: any) => {
            LoadingService.hide();
            AlertService.bottomModals(
              t("alerts.error"),
              t("alerts.embarrassing")
            );
          }
        );
      } else {
        LoadingService.hide();
        AlertService.bottomModals(
          t("alerts.gud"),
          t("alerts.exists"),
          t("alerts.confirm"),
          () => {},
          "olam-alert",
          false,
          false
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async grants(insertId) {
    let sqlPermission: string = `INSERT INTO user_permissions (id_user, reports, catalogs) VALUES (${insertId},1,1)`;
    await SqlGenericService.excecuteQueryStringReference(
      sqlPermission,
      "setPermissions"
    );
  }

  static async autologin(form, navigate, t) {
    let sql: string = `SELECT * FROM user WHERE password = SHA2(MD5(UNHEX(SHA2('${form[4].value}',512))),224) AND username = '${form[3].value}'`;
    SqlGenericService.excecuteQueryStringReference(sql, "login").then(
      async (response: any) => {
        if (response.data.data.length > 0) {
          let usTmp: any = response.data.data[0];
          sql = `SELECT * FROM user_permissions WHERE id_user = ${usTmp.id}`;

          let permisos: any =
            await SqlGenericService.excecuteQueryStringReference(
              sql,
              "getPermissions"
            );
          LoadingService.hide();

          usTmp.permisos = permisos.data.data[0];
          delete usTmp.password;
          localStorage.setItem(
            environment.userTagSession,
            JSON.stringify(usTmp)
          );
          FcmService.updateTokenUser();
          navigate("/home");
        } else {
          LoadingService.hide();
          AlertService.bottomModals("Oops", t("login.password"));
        }
      }
    );
  }

  static async resetPassword(email, password, navigate, t) {
    LoadingService.show();
    let sqlUpdatePassword: string = `UPDATE user SET password = SHA2(MD5(UNHEX(SHA2('${password}',512))),224) WHERE email = '${email}'`;
    SqlGenericService.excecuteQueryStringReference(
      sqlUpdatePassword,
      "resetPassword"
    ).then(() => {
      let form: any = [{}, {}, {}, { value: email }, { value: password }];
      this.autologin(form, navigate, t);
    });
  }
}

export const profileForm = [
  {
    type: "text",
    value: null,
    label: "register.name",
    placeholder: "register.name",
    maxLength: 100,
    required: true,
  },
  {
    type: "text",
    value: null,
    label: "register.last-name",
    placeholder: "register.last-name",
    maxLength: 200,
    required: true,
  },
  {
    type: "text",
    value: null,
    label: "register.second-name",
    placeholder: "register.second-name",
    maxLength: 200,
    required: false,
  },
  {
    type: "text",
    value: null,
    label: "register.email",
    placeholder: "register.email",
    maxLength: 200,
    required: true,
  },

  {
    type: "phone",
    regex: /^\+\d*$/,
    value: null,
    label: "register.phone",
    placeholder: "register.phone",
    maxLength: 200,
    required: true,
    hasEye: true,
    extraClass: "extraEye",
  },
];
