import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./services/Translate.service";
import { BrowserRouter as Router } from "react-router-dom";

import { LocalStorageEncryptService, init } from "arsaLocalv4";
import { marry, path, themeData } from "./environment/environment";

const container = document.getElementById("root");
const root = createRoot(container!);
let deviceData: any = {
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile:
    window.innerWidth <= 900,
}

if(!LocalStorageEncryptService.getFromLocalStorage("deviceData")){
  LocalStorageEncryptService.setToLocalStorage("deviceData", deviceData);
}

init({
  entryPath: `${path}${marry}`,
  isEmulate: true,
  environmentData: {
    genericQuerie: `${path}${marry}generic-querie`,
    petition: `${path}${marry}petition/`,
    loadFileOnly: `${path}${marry}load-only-file`,
    sendEmail: `${path}${marry}sendMail`,
  },
  themeData: themeData,
  SYSTEM_CONSTANTS: {
    mobileMinimum: 900,
    drawerWidth: 300,
    loginTemplate: "minviTe",
  },
  userTagSession: "merryMeSession"
});

root.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <App />
    </Router>
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
