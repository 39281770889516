import { useNavigate, useParams } from "react-router-dom";
import "./otp.scss";
import { useEffect, useState } from "react";
import {
  ArsaButton,
  ArsaContainer,
  ArsaVerificationCode,
  useTheme,
} from "arsaLocalv4";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useTranslation } from "react-i18next";
import password from "./../../assets/imgs/otp/password.png";
import ArsaLoginPresenter from "../Login/presenter/ArsaLogin.presenter";

export function OTP({}) {
  const { email, type } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [showButton, setShowButton] = useState(false);
  const [otp, setOtp] = useState<any>(null);

  useEffect(() => {
    setTimeout(() => {
      initTimer();
    }, 1000);
  }, []);

  function initTimer() {
    let timer: any = document.getElementById("timer");
    let presentTime: any = timer.innerHTML;
    let timeArray = presentTime.split(/[:]+/);
    let m: any = timeArray[0];
    let s = checkSecond(timeArray[1] - 1);
    if (s == 59) {
      m = m - 1;
    }
    if (m < 0) {
      return;
    }

    timer.innerHTML = m + ":" + s;
    if (timer.innerHTML == "0:00") {
      setShowButton(true);
      ArsaLoginPresenter.resetOTP(email);
    }
    setTimeout(initTimer, 1000);
  }

  function checkSecond(sec) {
    if (sec < 10 && sec >= 0) {
      sec = "0" + sec;
    } // add zero in front of numbers < 10
    if (sec < 0) {
      sec = "59";
    }
    return sec;
  }

  function resendCode() {
    ArsaLoginPresenter.recoveryPassword({
      formForgot: [
        {
          type: "text",
          value: email,
          label: "login.username",
          placeholder: "login.username",
        },
      ],
      navigate: navigate,
      t: t,
      setFormForgot: null,
      initTimer,
      setShowButton
    });
  }

  function validateCode(){
    ArsaLoginPresenter.validateOTP(email, otp, type, navigate, t);
  }

  return (
    <>
      <ArsaHeader
      extraClass="header-otp"
        title={''}
        applyPadding={true}
        backActive={true}
        menuActive={false}
        backAction={() => {
          navigate(-1);
        }}
      /> 
      <ArsaContainer customId={"main-content"} applyPadding={true}>
        <section className="otp-ctn">
          <div
            className="top-page"
            style={{ backgroundColor: theme.background }}
          >
            <img src={require('./../../assets/imgs/otp/password.png')} alt="codigo otp" />
            <p className="otp-title" style={{ color: theme.color }}>
              O T P
            </p>
            <p className="otp-description" style={{ color: theme.color }}>
              {t("otp.description")}
            </p>
          </div>
          <div className="bottom-page">
            <ArsaVerificationCode
              lengthSquare={6}
              onChangeVerifyCode={(code: string) => {
                setOtp(code);
              }}
            />
            {!showButton && (
              <div className="resend-container">
                <ArsaButton
                  onClick={() => {
                    validateCode();
                  }}
                >
                  Continue
                </ArsaButton>
              </div>
            )}

            <p className="didnt">Didn’t receive a code?</p>
            {!showButton ? (
              <p id="timer" className="timer">
                5:00
              </p>
            ) : (
              <div className="resend-container">
                <ArsaButton
                  extraClass="outline-button"
                  onClick={() => {
                    resendCode();
                  }}
                >
                  Resend
                </ArsaButton>
              </div>
            )}
          </div>
        </section>
      </ArsaContainer>
    </>
  );
}
