import { useTranslation } from "react-i18next";
import { ArsaHeader } from "./../../components/arsa-header/ArsaHeader";
import "./listInvites.scss";
import {
  ArsaButton,
  ArsaContainer,
  ArsaFabButton,
  useDeviceDetect,
  Context as ModalContext,
  ThemeService,
  ArsaTable,
  ArsaPaginator,
  Paginator,
  TableTemplate,
  useTheme,
} from "arsaLocalv4";
import { useIonModal } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { InviteCrud } from "./InviteCrud";
import { InvitePresenter } from "./presenter/Invite.presenter";
import { themeData } from "../../environment/environment";
import { UtilLocalService } from "../../services/UtilLocalService";
import { create, trash, eye } from "ionicons/icons";
import { useNavigate } from "react-router-dom";

export function ListInvites({}) {
  const { t, i18n } = useTranslation();
  const [currentLineItem, setCurrentLineItem] = useState<any>();
  /* const [presentModal, dismiss] = useIonModal(InviteCrud, {
    data: { ...currentLineItem },
    onDismiss: (data: string, role: string) => dismiss(data, role),
  }); */
  const [invite, setInvite] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [deviceWidth, setDeviceWidth] = useState<boolean>(false);
  const [myInvites, setMyInvites] = useState<any>([]);
  const device: any = useDeviceDetect();
  const modal = useContext<any>(ModalContext);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableTemplate | null>(null);
  const { theme } = useTheme();
  const [first, setFirst] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [paginator, setPaginator] = useState<any>({
    actual: 1,
    prev: 1,
    next: 2,
    afterNext: 3,
    maximum: 0,
  });

  async function openInvite2(
    square: any = null,
    edit: boolean,
    register: any = null
  ) {
    setCurrentLineItem({
      square: square,
      edit: edit,
      catalogRegister: register,
    });
    /* presentModal({
      onWillDismiss: (ev: any) => {
        if (ev.detail.role === "confirm") {
        }
      },
      cssClass: !device.isMobile ? "modal-olam-channel-web" : "modal-olam-80",
      mode: "ios",
    }); */
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(()=>{
    setDeviceWidth(device.isMobile);
  },[device]);

  function openInvite(invite: any = null, edit: boolean) {
    let data = {
      edit: edit,
      invite,
    };

    modal.showModal({
      title: t("login.reset"),
      component: (
        <InviteCrud
          data={{ ...data }}
          close={modal.closeModal}
          modal={modal}
          loadInvites={init}
        />
      ),
      footer: false,
      extraClassContainer: "bottom-modal",
      isKeepOpen: device.isMobile ? true : false,
      continueAction: () => {},
      buttonText: "Continuar",
    });
  }

  async function openInviteOptions(invitation) {
    console.log(invitation);
    
    UtilLocalService.openActionSeets([
      {
        text: t("invite.eye"),
        icon: eye,
        handler: async () => {
          //navigate(`/invitation/${invitation.id}`);
          window.open(`/invitation/${invitation.name}`, "_blank")
        },
      },
      {
        text: t("invite.edit"),
        icon: create,
        handler: async () => {
          openInvite(invitation, true);
        },
      },
      {
        text: t("invite.delete"),
        icon: trash,
        handler: async () => {
          await InvitePresenter.deleteInvite(invitation, setMyInvites);
        },
      },
      {
        text: t("alerts.cancel"),
        role: "cancel",
        handler: () => {},
      },
    ]);
  }

  async function init() {
    getInvites(false, paginator);
  }

  async function getInvites(first: boolean = false, newPaginator: any = null) {
    await InvitePresenter.getAllInvites(
      setLoad,
      setMyInvites,
      setTableData,
      t,
      limit,
      newPaginator,
      setPaginator,
      first,
      openInvite,
      theme,
      navigate
    );
  }

  return (
    <>
      <ArsaHeader title={t("home.home")} applyPadding={true} />

      <ArsaContainer
        customId={"main-content-crud-prcl"}
        applyPadding={true}
        noPadding={!device.isMobile}
        themeBackgroundActive={false}
      >
        <section
          style={{
            marginBottom: "140px",
          }}
        >
          {deviceWidth ? (
            myInvites.map((invitation: any) => {
              return (
                <div
                  className="card-invite"
                  onClick={() => {
                    openInviteOptions(invitation);
                  }}
                >
                  <div
                    className="status-line"
                    style={{
                      backgroundColor: ThemeService.getThemeData().component,
                    }}
                  ></div>

                  <div className="image-container">
                    <img
                      src={
                        invitation.url_img
                          ? invitation.url_img
                          : require("../../assets/imgs/logos/logoApp.png")
                      }
                    />
                  </div>

                  <div className="invite-info">
                    <p className="title">
                      {invitation.json_invite.principalForm[0].value ??
                        t("invite.not-found")}
                    </p>
                    <p className="description">
                      {invitation.json_invite.principalForm[2].value ??
                        t("invite.not-found-who")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <ArsaTable template={tableData} />
          )}
          {device?.isMobile ? null : (
            <section
              style={{
                padding: "0 16px",
              }}
            >
              <ArsaPaginator
                paginator={paginator}
                paginatorOut={(newPaginator) => {
                  setPaginator(newPaginator);
                  setTimeout(() => {
                    getInvites(true, newPaginator);
                  }, 400);
                }}
              />
            </section>
          )}
        </section>
      </ArsaContainer>
      <ArsaFabButton
        extraClass="fb-create"
        onClick={() => {
          openInvite(invite, false);
        }}
      >
        <span className="material-icons">add</span>
      </ArsaFabButton>
    </>
  );
}
