import textInput from "../../../assets/svg/textInput.svg";

export class AboutPresenter {}

export const CONTACT_FORM = [
  {
    type: "text",
    value: null,
    label: "about.subject",
    placeholder: "about.subject2",
    id:"subjectf",
    required: true,
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
  {
    type: "textarea",
    value: null,
    label: "about.what",
    placeholder: "about.what",
    required: true,
    id:"subjectf2",
    hasTopLabel: true,
    hasError: false,
    error: "invite.errors.name",
    globalExtraClass: "invite-input",
    extraLeftComponent: (
      <div className="custom-icon-left">
        <img src={textInput} alt="" />
      </div>
    ),
  },
];
