import { useTranslation } from "react-i18next";
import "./profile.scss";

import {
  ArsaContainer,
  useDeviceDetect,
  useTheme,
  
  useUserSession,
  
  UtilService,
} from "arsaLocalv4";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ProfilePresenter, { profileForm } from "./presenter/profile.presenter";
import { getLinearProgressUtilityClass } from "@mui/material";
import loaderGif from "./../../assets/imgs/gifs/loader.gif";
import { UtilLocalService } from "../../services/UtilLocalService";

import { cameraOutline, imageOutline, archiveOutline } from "ionicons/icons";
import { ArsaForm } from "../../components/arsa-form";

export function Profile({modal}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formRegister, setRegisterForm] = useState(profileForm);
  const [picture, setPicture] = useState<any>(null);
  const formReferences = useRef(null);
  const { theme } = useTheme();
  const device: any = useDeviceDetect();
  const { user } = useUserSession();
  
  useEffect(() => {
    init();
  }, []);

  function init() {
    let formRegisterCopy:any = [...formRegister];
    formRegisterCopy[0].value = user.name;
    formRegisterCopy[1].value = user.last_name;
    formRegisterCopy[2].value = user.second_last_name;
    formRegisterCopy[3].value = user.email;
    formRegisterCopy[4].value = user.phone;
    setPicture(user.url_img)
    setRegisterForm(formRegisterCopy);
  }

  async function opcionesIMG(opc: number, isMultiple: boolean = false) {
    UtilLocalService.openActionSeets([
      /* {
        text: t("PHOTO"),
        icon: cameraOutline,
        handler: async () => {
          setPicture(await UtilLocalService.takeFoto()); //this.takeFoto(true, isMultiple);
        },
      },
      {
        text: t("BUTTON-9"),
        icon: imageOutline,
        handler: async () => {
          setPicture(await UtilLocalService.takeFoto(false));
        },
      }, */
      {
        text: t("FILES"),
        icon: archiveOutline,
        handler: async () => {
          setPicture(
            await UtilLocalService.openFile({
              t: t,
            })
          );
        },
      },
      {
        text: t("alerts.cancel"),
        role: "cancel",
        handler: () => {},
      },
    ]);
  }


  return (
    <>
      <ArsaHeader
        title={t("register.title")}
        applyPadding={true}
        isModal={true}
        closeModal={() => {
          //onDismiss();
          modal.closeModal();
        }}
      />
      <ArsaContainer
        customId={"main-content-crud-prcl"}
        extraClass="ctn222"
        applyPadding={true}
        noPadding={!device.isMobile}
        themeBackgroundActive={false}
      >
        <section className="about">
          <section className="boxm boxm2">
            <ArsaForm
              data={formRegister}
              form={formReferences}
              onChangeForm={(e, index) => {
                
              }}
              buttonAction={() => {
                ProfilePresenter.sendForm(
                  formRegister,
                  setRegisterForm,
                  t,
                  i18n,
                  picture,
                  navigate,
                  user,
                  modal
                );
              }}
              buttonText={t("register.ok")}
            >

              <div
                className="technicians"
                style={{
                  backgroundColor: theme.color,
                }}
              >
                <div
                  style={{
                    backgroundColor: theme.background,
                  }}
                >
                  <p
                    style={{
                      color: theme.color,
                      margin: "0",
                    }}
                  >
                    {t("register.profile-image")}
                  </p>
                </div>
              </div>

              <div
                className="contenedor-logo"
                style={{
                  borderColor: theme.color,
                }}
              >
                <label
                  className="llabel"
                  onClick={() => {
                    opcionesIMG(1, false);
                  }}
                >
                  {!picture && <img src={loaderGif} className="w-picture" />}
                  {picture && <img src={picture} className="w-picture" />}
                </label>

                <p
                  style={{
                    color: theme.color,
                    verticalAlign: "middle",
                  }}
                >
                  {t("register.images")}
                </p>
              </div>
            </ArsaForm>
          </section>
        </section>
      </ArsaContainer>
    </>
  );
}
