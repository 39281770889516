import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
} from "@capacitor/push-notifications";

import { Device } from "@capacitor/device";

import {
  LocalStorageEncryptService,
  AlertService,
  LoadingService,
  environment,
  SqlGenericService,
} from "arsaLocalv4";

export interface FCMJson {
  to: string;
  notification: FCMNotification;
  data: FCMData;
  priority: string;
}

export interface FCMNotification {
  body: string;
  title: string;
  click_action: string;
  image: string;
  color: string;
  "content-available": boolean;
}

export interface FCMData {
  body: string;
  title: string;
  view: number;
  otherData?: any;
}

export class FcmService {
  static initPush() {}

  static async registerPush() {
    await PushNotifications.requestPermissions();

    await PushNotifications.register().then((t: any) => {
      ////console.log("register tokokok");
      ////console.log(t);
    });

    const uuid = await (await Device.getId()).identifier;

    PushNotifications.addListener("registration", (data) => {
      // alert(JSON.stringify(data));
      ////console.log("Disque register");

      ////console.log(data);

      let token: any =
        LocalStorageEncryptService.getFromLocalStorage("token-arsa");
      if (!token) {
        let token: string = data.value;

        //Se registra correctamente nuevo usuario
        LoadingService.hide();
        LocalStorageEncryptService.setToLocalStorage("token-arsa", token);

        this.registerToken(uuid, token);
        // FCM.subscribeTo({ topic: 'simsaAD' });//se suscribe a notificaciones globales de la app
      }
      //PushNotifications.
    });

    this.listenNotifications();

    // Get FCM token instead the APN one returned by Capacitor

    PushNotifications.addListener("registrationError", (error: any) => {
      ////console.log('Error: ' + JSON.stringify(error));
    });

    //this.listenNotifications();
  }

  static listenNotifications() {
    PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        ////console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        ////console.log('notification ' + JSON.stringify(notification));
        ////console.log(notification);
        AlertService.bottomModals(notification.title!, notification.body!);
      }
    );
    PushNotifications.requestPermissions().then((response) => {
      ////console.log(`reeeee`);
      ////console.log(response);

      PushNotifications.register().then((res: any) => {
        ////console.log(`registered for push`);
        ////console.log(res);
      });
    });

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: ActionPerformed) => {
        //console.log("PERFORMANCE----------------------");
        //console.log(notification);
        //console.log(notification.notification);
        //console.log(notification.notification.data);

        const data = notification.notification.data;
        //console.log(data);
        data.otherData = JSON.parse(data?.otherData);
        //console.log(data.otherData);

        switch (notification?.notification?.data?.view) {
          case 1:
            //hay que abrir la reunion que empieza
            // this.router.navigate(["meet", notification?.notification?.data?.otherData?.reunion]);
            break;

          default:
            break;
        }
        if (data.detailsId) {
          //this.router.navigateByUrl(`/home/${data.detailsId}`);
        }
      }
    );
  }

  static updateTokenUser() {
    let user: any = LocalStorageEncryptService.getFromLocalStorage(
      environment.userTagSession
    );
    let token: any =
      LocalStorageEncryptService.getFromLocalStorage("token-arsa");

    let tz: any = Intl.DateTimeFormat().resolvedOptions();

    let sqlUpdate: string = `UPDATE user SET token = '${token}', last_session = now() WHERE id = ${user.id}`;

    if (user) {
      SqlGenericService.excecuteQueryStringReference(
        sqlUpdate,
        "updateUserToken"
      ).then(
        (response: any) => {
          this.registerPush();
        },
        (error: any) => {}
      );
    }
  }

  static registerToken(uuid: any, token: any) {
    let registerToken: any = LocalStorageEncryptService.getFromLocalStorage("token-arsa");
    let lenguaje: any = LocalStorageEncryptService.getFromLocalStorage("language");
    if (!registerToken) {
      //token is token of device
      let uidFake: any = uuid;
      //let token: string = registerToken;
      let sqlUpdate: string = `UPDATE user SET token = '${token}', width = ${
        window.innerWidth
      }, height = ${window.innerHeight}, language = ${
        lenguaje ? lenguaje : "en"
      }, last_session = now()  WHERE uuid = '${uidFake}'`;

      let sqlInsert: string = `INSERT INTO user (uuid, token, width, height, language) VALUES ('${uidFake}', '${token}', ${
        window.innerWidth
      }, ${window.innerHeight}, ${lenguaje ? lenguaje : "en"})`;

      let sqlChecking: string = `SELECT * FROM user WHERE uuid = '${uidFake}'`;

      //consultar uuid en base de datos antes de registrar nuevo token
      //Si encuentra el uuid se actualizará el token pero no creará nuevo usuario

      SqlGenericService.excecuteQueryStringReference(sqlChecking, "checkRegister")
        .then(
          (resp: any) => {
            SqlGenericService.excecuteQueryStringReference(sqlUpdate, "updateRegister")
              .then(
                (respInsert: any) => {
                  LocalStorageEncryptService.setToLocalStorage(
                    "token-arsa",
                    token
                  );
                },
                (err: any) => {}
              );
          },
          (err: any) => {}
        );
    } else {
      //this.listenNotifications();
    }
  }
}
