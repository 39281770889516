import { AlertService, SqlGenericService, TableTemplate } from "arsaLocalv4";

export class PackagePresenter {
  public static async getPackages(theme, setTable, t, setPackages) {
    try {
      let sqlGetInvites: string = `SELECT * FROM catalog WHERE id_catalog_type = 4`;
      let response: any = await SqlGenericService.excecuteQueryStringReference(
        sqlGetInvites,
        "getPackages"
      );
      setPackages(response?.data?.data);
      if (response?.data?.data[0]) {
        let responseTmp: any = response.data.data;
        let tableData: TableTemplate = {
          headers: [
            {
              title: t("invite.invite-prncl"),
              width: 160,
            },
            {
              title: t("packages.name"), width: 150
            },
            {
              title: t("packages.description"),width: 200
            },
            { title: t("invite.invite-actions"), width: 70 },
          ],
          content: [],
          extraClass: "invite-table-data",
        };

        responseTmp.forEach((itm: any) => {
          tableData.content.push({
            label: "",
            components: [
              <div className="img-prncl2">
                <img src={itm.dumy_property} />
              </div>,
              <div style={{textAlign: "left"}}>{t(itm.name)}</div>,
              <div style={{textAlign: "left"}}>{t(itm.description)}</div>,
              <div className="actions">
                <div
                  style={{
                    backgroundColor: theme.component,
                  }}
                  onClick={() => {}}
                >
                  <span className="material-icons">edit</span>
                </div>
              </div>,
            ],
          });
        });

        setTable(tableData);
      } else {
        AlertService.bottomModals(t("alerts.error"), t("alerts.embarrassing"));
      }
    } catch (error) {
      console.log(error);
    }
  }
}
