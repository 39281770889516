import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { ActionSheetButton, useIonActionSheet } from "@ionic/react";
import { LoadingService, AlertService, useTheme } from "arsaLocalv4";
import React from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

export function getFileReader(): FileReader {
  const fileReader = new FileReader();
  const zoneOriginalInstance = (fileReader as any)[
    "__zone_symbol__originalInstance"
  ];
  return zoneOriginalInstance || fileReader;
}

declare global {
  interface Window {
    resolveLocalFileSystemURL: any;
  }
}

const ActionSheetComponent: React.FC<any> = ({ buttons }) => {
  const [present] = useIonActionSheet();
  const { t } = useTranslation();
  const { theme } = useTheme();

  React.useEffect(() => {
    present({
      header: t("home.selection"),
      cssClass: `action-sheets-olam ${theme.sheet}`,
      buttons: buttons,
    });
  }, [present, t, theme.sheet, buttons]);

  return null; // Puedes devolver cualquier cosa o nada, ya que no estás utilizando un render visible
};

export class UtilLocalService {
  static async takeFoto(isCamera: boolean = true, isMultiple: boolean = false) {
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos,
    });
    let base64Image: string = "data:image/jpeg;base64," + image.base64String;
    return this.gotPhoto(base64Image, isMultiple);
  }

  static compress(
    file: any,
    returnBase64: boolean = true,
    factor: number = 5
  ): Promise<any> {
    //console.log(file);
    file = file.target ? file.target.files[0] : file;
    const width = 500;
    const height = 500;
    const fileName = file.name;

    //const reader = new FileReader();
    let reader = getFileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      //console.log('1');

      (reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;
        //console.log('2');
        (img.onload = () => {
          let widthi = 0;
          let heighti = 0;
          if (file.size > 1000000) {
            widthi = img.width / factor;
            heighti = img.height / factor;
          } else {
            widthi = img.width;
            heighti = img.height;
          }
          //console.log('5');
          const elem = document.createElement("canvas");
          elem.width = widthi;
          elem.height = heighti;
          const ctx: any = elem.getContext("2d");
          //ctx.globalAlpha = 0.2;
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, widthi, heighti);
          //console.log('6');
          ctx.canvas.toBlob(
            async (blob: any) => {
              //console.log('7');
              let filet: any = new File([blob], fileName, {
                type: "image/png",
                lastModified: Date.now(),
              });
              //console.log('8');
              let resultado = await this.getBase64(file);
              resolve(returnBase64 ? resultado : filet);
              //Logica de nuevo file
            },
            "image/png",
            1
          );
        }),
          (img.onerror = (error: any) => {
            //console.log('4');
            reject(error);
          });
      }),
        (reader.onerror = (error: any) => {
          //console.log('3');
          //console.log(error);

          reject(error);
        });
    });
  }

  static getBase64(file): Promise<any> {
    // const reader = new FileReader();
    let reader = getFileReader();
    reader.readAsDataURL(file);
    //console.log('9');
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        //console.log('10');
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        //console.log('11');
        reject(error);
      };
    });
  }

  static gotPhoto(imageUri, isMultiple: boolean = false) {
    return new Promise((resolve, reject) => {
      try {
        window.resolveLocalFileSystemURL(imageUri, (fileEntry) => {
          fileEntry.file((fileObj) => {
            setTimeout(async () => {
              let img: any = await this.compress(fileObj);
              resolve(img);
            }, 1000);
          });
        });
      } catch (error) {
        reject(null);
      }
    });
  }

  static openActionSeets(buttons: Array<ActionSheetButton>) {
    try {
      const container = document.createElement("div");
      document.body.appendChild(container);
      const component = <ActionSheetComponent buttons={buttons} />;
      ReactDOM.render(component, container);

      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
      }, 0);
    } catch (error) {
      console.error(error);
    }
  }

  static async openFile({
    acceptedFiles = ["image/jpeg", "image/jpg", "image/png"],
    wannaB64 = true,
    t,
  }) {
    
    return new Promise((resolve, reject) => {
      var input = document.createElement("input");
      input.type = "file";
      input.style.visibility = "hidden";
      input.style.position = "absolute";
      input.className = "hidden-input"; // set the CSS class
      document.body.appendChild(input); // put it into the DOM
      input.click();

      input.addEventListener("change", async (e: any) => {
        let file = e.target.files[0];
        LoadingService.show();
        
        let filteredType: any = acceptedFiles.filter(
          (af: any) => af == file.type
        );
        if (!filteredType || filteredType.length <= 0) {
          AlertService.bottomModals(t("alerts.wait"), t("alerts.file"));
        }

        if (file) {
          LoadingService.hide();
          
          if (!this.isVideoFile(file) && !file.type.includes("audio")) {
            resolve(await this.compress(e));
          }else if(file.type.includes("audio")){
            resolve(wannaB64 ? await this.convertAudioToBase64(file) : file );
          } else {
            resolve(wannaB64 ? await this.convertVideoToBase64(file) : file);
          }
        } else {
          LoadingService.hide();
          reject(new Error("No se seleccionó ningún archivo."));
        }
        document.body.removeChild(input);
      });
    });
  }

  static isVideoFile(file: File): boolean {
    // Verifica la extensión del archivo o el tipo MIME para determinar si es un video
    const allowedExtensions = ["mp4", "mov", "avi", "mkv"]; // Puedes agregar más extensiones según tus necesidades

    // Verifica la extensión
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      return true;
    }

    // Verifica el tipo MIME
    const allowedMimeTypes = [
      "video/mp4",
      "video/quicktime",
      "video/x-msvideo",
      "video/x-matroska",
    ];
    if (allowedMimeTypes.includes(file.type)) {
      return true;
    }

    return false;
  }

  static convertVideoToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  static convertAudioToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader:any = new FileReader();

        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Obtener solo el contenido Base64
            resolve(base64String);
        };

        reader.onerror = error => reject(error);

        reader.readAsDataURL(file);
    });
}
}
