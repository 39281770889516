import { useEffect, useRef, useState } from "react";
import RegisterPresenter, { registerNewPassForm } from "../Register/presenter/register.presenter";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AlertService, ArsaForm, UtilService } from "arsaLocalv4";

export function NewPassword({ email }: { email: string }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const formReferences = useRef(UtilService.getRandomNumber(10));
  const [formRegister, setRegisterForm] = useState<any>(registerNewPassForm);

  useEffect(()=>{
    let nForm:any = [...registerNewPassForm];
    nForm.forEach(element => {
        element.value = "";
        element.hasError = false;
    });
    setRegisterForm(nForm);
  },[]);

  return (
    <section>
      <ArsaForm
        data={formRegister}
        form={formReferences}
        onChangeForm={(e, index) => {
          let form: any = [...formRegister];
          setRegisterForm(form);
        }}
        buttonAction={() => {
          let errors: number = 0;
          let formStep:any = [...formRegister];
          if (formStep[0].value != formStep[1].value) {
            formStep[1].hasError = true;
            formStep[1].errorMessage = `${t("alerts.passwords")}`;
            errors++;
          }else{
            formStep[1].hasError = false;
          }
          setRegisterForm(formStep);
          if (errors == 0) {
            RegisterPresenter.resetPassword(email, formStep[0].value, navigate, t);
          }
        }}
        hasAction={true}
        buttonText={t("register.reset")}
      >
        <div
          className="regex-errors"
          key={`regexes-${UtilService.getRandomText(9)}`}
        >
          {formRegister.map((itemForm: any) => {
            if (itemForm.regexes) {
              itemForm.regexes.map((regex: any) => {
                regex.isValid = regex.regex.test(itemForm.value);
              });
              let findRegexError = itemForm.regexes.filter(
                (regex: any) => !regex.isValid
              );
              return itemForm.regexes.map((regex: any) => {
                return (
                  <div
                    className="regex-print"
                    key={`regex-${UtilService.getRandomText(9)}`}
                  >
                    <span className={regex.isValid ? "valid-regex" : ""}>
                      ⬤
                    </span>{" "}
                    {t(regex.message)}
                  </div>
                );
              });
            } else {
              return null;
            }
          })}
        </div>
      </ArsaForm>
    </section>
  );
}
