import { idEmpresa } from "../../../../../environment/environment";

import {
  AlertService,
  LoadingService,
  SqlGenericService,
  EventService
} from "arsaLocalv4";

export default class CardCrudPresenter {
  constructor() {}

  static createOrUpdateCatalog(
    catalog: any,
    updateData: any,
    translator: any,
    edit: boolean,
    id: any,
    square: any,
    close = () => {}
  ) {
    let data: any = [...catalog];
    let searchErrorsKey: any = Object.keys(data);
    let error: number = 0;
    searchErrorsKey.forEach((itm: any) => {
      if (!data[itm].value || data[itm].value.length <= 0) {
        data[itm].hasError = true;
        //data[itm].msjError = translator("alerts.required");
        error++;
      } else {
        data[itm].hasError = false;
        data[itm].error = "";
      }
    });
    updateData(data);
    if (error <= 0) {
      LoadingService.show();
      let sql: string = edit
        ? `UPDATE catalog SET description = '${data[1].value}', name = '${
            data[0].value
          }', dumy_property = '${
            data[2] ? data[2].value : null
          }' WHERE id = ${id}`
        : `INSERT INTO catalog (id_catalog_type, id_industry, name, description, dumy_property) VALUES (${
            square.type
          }, ${idEmpresa}, '${data[0].value}', '${data[1].value}', '${
            data[2] ? data[2].value : null
          }')`;

      SqlGenericService
        .excecuteQueryStringReference(
          sql,
          edit ? "updateCatalog" : "createCatalog"
        )
        .then(
          (response: any) => {
            LoadingService.hide();
            EventService.send("updateCatalog", true);
            let formTmp: any = [...catalog];
            formTmp[0].value = null;
            formTmp[1].value = null;
            updateData(formTmp);
            close();
            AlertService.bottomModals(
              translator("alerts.gud"),
              translator(!edit ? "catalogs.generate" : "catalogs.updater")
            );
          },
          (error: any) => {
            LoadingService.hide();
            AlertService.bottomModals(
              translator("alerts.error"),
              translator("alerts.embarrassing")
            );
          }
        );
    }
  }
}

export const INITIAL_CRUD_FORM = [
  {
    type: "text",
    value: null,
    label: "crud.name",
    placeholder: "crud.name",
    required: true,
    hasError: false,
    error: "crud.errors.name",
  },
  {
    type: "textarea",
    value: null,
    label: "crud.description",
    placeholder: "crud.description",
    required: true,
    hasError: false,
    error: "crud.errors.description",
  },
];
