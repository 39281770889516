import {
  ArsaContainer,
  useDeviceDetect,
  AlertService,
  LoadingService,
  HttpService,
  useUserSession,
  ArsaForm,
  environment,
} from "arsaLocalv4";
import "./about.scss";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { App } from "@capacitor/app";
import packageJson from "../../../package.json";
import { CONTACT_FORM } from "./presenter/About.presenter";

export function About({}) {
  const device: any = useDeviceDetect();
  const { t, i18n } = useTranslation();
  const formRef = useRef(new Date().getTime());
  const [form, setForm] = useState(CONTACT_FORM);

  const { user } = useUserSession();

  useEffect(() => {
    pageInjection();
  }, []);

  function pageInjection() {
  }

  function addcss(css) {
    let head: any = document.getElementsByTagName("head")[0];
    let s: any = document.createElement("style");
    s.setAttribute("type", "text/css");
    if (s.styleSheet) {
      // IE
      s.styleSheet.cssText = css;
    } else {
      // the world
      s.appendChild(document.createTextNode(css));
    }
    head.appendChild(s);
  }

  return (
    <>
      <ArsaHeader title={t("home.about")} applyPadding={true} />
      <ArsaContainer
        customId={"main-content-crud-prcl"}
        extraClass="ctn222"
        applyPadding={true}
        noPadding={!device.isMobile}
        themeBackgroundActive={false}
      >
        <section className="about">
          <section className="boxm">
            <div className="logom">
              <img
                src={require("../../../src/assets/imgs/logos/logoApp.png")}
                alt="logo"
              />
              <p className="name-app">
                |-- <strong>{packageJson.name}</strong> --|
              </p>
            </div>

            <div className="version">
              <p>Version</p>
              <p>{packageJson.version}</p>
            </div>

            <div className="version">
              <p>Developer</p>
              <p>{packageJson.developer}</p>
            </div>

            <div className="version">
              <p>{t("about.talk")}</p>
            </div>
            <ArsaForm
              data={form}
              form={formRef}
              hasAction={true}
              buttonAction={() => {
                if (
                  form[0].value &&
                  String(form[0].value).length > 0 &&
                  form[1].value &&
                  String(form[1].value).length > 0
                ) {
                  let request: any = {
                    asunto: "MarryMeApp",
                    from: "contacto@olam-systems.com.mx",
                    name: user.name,
                    to: "sarrejuan@gmail.com",
                    cuerpo: `<div>
                    <p>Asunto: ${form[0].value}</p>
                    <p>cuerpo: ${form[1].value}</p>
                    <p>soy: ${JSON.stringify(user)}</p>
                    </div>`,
                  };

                  LoadingService.show();
                  HttpService.post(
                    `${environment.environmentData.sendEmail}`,
                    request
                  )
                    .then((responseFile: any) => {
                      LoadingService.hide();
                      let fCopy:any = [...form];
                      fCopy.forEach(element => {
                        element.value = "";
                        (document.getElementById(element.id) as any).value = "";
                      });

                      setForm(fCopy);
                      AlertService.bottomModals(
                        t("alerts.gud"),
                        t("alerts.need")
                      );
                    })
                    .catch((e) => {
                      LoadingService.hide();
                      AlertService.bottomModals(
                        t("alerts.error"),
                        t("alerts.embarrassing")
                      );
                    });
                }
              }}
              buttonText={t("alerts.send")}
              extraClass={"about-form"}
              onChangeForm={(e, index) => {}}
            ></ArsaForm>

            <div className="version">
              <p>
                <strong>© Copyright {new Date().getFullYear()}</strong>
              </p>
            </div>
          </section>
        </section>

        <div className="powered2">
          <p>Powered by ©</p>
          <img
            src={require("../../assets/imgs/logos/logoOlam.png")}
            alt="Arsa Consulting Logo"
          />
        </div>
      </ArsaContainer>
    </>
  );
}
