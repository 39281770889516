
import {
  LoadingService,
  AlertService,
  SqlGenericService
} from "arsaLocalv4";

export default class CardCrudRegistersPresenter {
  constructor() {}

  static load(setLoad, setRegisters, setRegistersTemp, type, translator) {
    setLoad(true);
    let sql: string = `SELECT * FROM catalog WHERE id_catalog_type = ${type}`;
    let allData: any = SqlGenericService
      .excecuteQueryStringReference(sql, "getRegistersFromCatalogType")
      .then(
        (response: any) => {
          setLoad(false);
          setRegisters(response.data);
          setRegistersTemp(response.data);
        },
        (error: any) => {
          setLoad(false);
          AlertService.bottomModals(
            translator("alerts.error"),
            translator("alerts.embarrassing")
          );
        }
      );
  }

  static delete(register:any, registersTemp:any, setRegistersTemp:any, setRegisters:any, translator){
    let sql: string = `DELETE FROM catalog WHERE id = ${register.id}`;
    LoadingService.show();
    let allData: any = SqlGenericService
      .excecuteQueryStringReference(sql, "getRegistersFromCatalogType")
      .then(
        (response: any) => {
          let registersCopy:any = [...registersTemp];
          let position = registersCopy.findIndex((elemento) => {
            return elemento.id == register.id;
          });
          registersCopy.splice(position, 1);
          setRegisters(registersCopy);
          setRegistersTemp(registersCopy);
          LoadingService.hide();

          AlertService.bottomModals(
            translator.instant('alerts.gud'),
            translator.instant('catalog.success-delete')
          );

        },
        (error: any) => {
          LoadingService.hide();
          AlertService.bottomModals(
            translator("alerts.error"),
            translator("alerts.embarrassing")
          );
        }
      );
  }

}
