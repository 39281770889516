import { SqlGenericService, TableTemplate } from "arsaLocalv4";

export class EventsPresenter {
  static async getPublicEvents(setLoader, setEvents, setTableData, t, theme, navigate) {
    try {
      let getPublicEventsSql: string = `SELECT * FROM invites WHERE is_public_event = 1 AND expire_date > now()`;
      let tableData: TableTemplate = {
        headers: [
          {
            title: t("invite.invite-prncl"),
            width: 160,
          },
          {
            title: t("invite.invite-title"),
          },
          {
            title: t("invite.invite-code"),
          },
          {
            title: t("invite.invite-at"),
          },
          { title: t("invite.invite-actions"), width: 100 },
        ],
        content: [],
        extraClass: "invite-table-data",
      };
      
      let response: any = await SqlGenericService.excecuteQueryStringReference(
        getPublicEventsSql,
        "getPublicEvents"
      );

      let responseTmp: any = response.data.data;
      responseTmp.forEach((itm: any) => {
        
        try {
          itm.json_invite = JSON.parse(itm.json_invite);
        } catch (error) {
          itm.json_invite = {};
        }

        tableData.content.push({
          label: "",
          components: [
            <div className="img-prncl">
              <img
                src={itm.url_img}
                alt={itm.json_invite?.principalForm[0]?.value}
              />
            </div>,
            <div>
              {" "}
              {itm.json_invite?.principalForm[0]?.value
                ? itm.json_invite?.principalForm[0]?.value
                : "S/N"}{" "}
            </div>,
            <div> {itm.invite_code} </div>,
            <div> {itm.date_at} </div>,
            <div className="actions">
              <div
              style={{
                backgroundColor: theme.component,
              }}
                onClick={() => {
                  navigate(`/invitation/${itm.id}`);
                }}
              >
                <span className="material-icons">visibility</span>
              </div>
            </div>,
          ],
        });
      });
      setLoader(false);
      setTableData(tableData);
      setEvents(response.data.data);
    } catch (error) {}
  }
}
