import { ArsaForm } from "arsaLocalv4";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export function Forgot({form}) {
  const { t } = useTranslation();
  
  const formRef = useRef(new Date().getTime());
  return (
    <section className="forgot-ctn">
      <p>{t("login.enter")}</p>
      <ArsaForm
        data={form}
        form={formRef}
        buttonAction={() => {}}
        hasAction={false}
        buttonText={""}
        extraClass={"crud-form"}
      />
    </section>
  );
}
