import { useEffect, useRef, useState } from "react";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import "./packages.scss";
import {
  ArsaButton,
  ArsaContainer,
  ArsaTable,
  TableTemplate,
  ThemeService,
  useDeviceDetect,
  useTheme,
} from "arsaLocalv4";
import { useTranslation } from "react-i18next";
import { PackagePresenter } from "./presenter/package.presenter";

export function Packages({}) {
  const device: any = useDeviceDetect();
  const { t, i18n } = useTranslation();
  const { theme } = useTheme();
  const formRef = useRef(new Date().getTime());
  const [tableData, setTableData] = useState<TableTemplate | null>(null);
  const [packages, setPackages] = useState<any>([]);
  const [deviceWidth, setDeviceWidth] = useState<boolean>(false);
  useEffect(() => {
    init();
  }, []);

  async function init() {
    await PackagePresenter.getPackages(theme, setTableData, t, setPackages);
  }

  useEffect(() => {
    setDeviceWidth(device.isMobile);
  }, [device]);

  return (
    <>
      <ArsaHeader title={t("packages.title")} applyPadding={true} />

      <ArsaContainer
        customId={"main-content-crud-prcl"}
        applyPadding={true}
        noPadding={!device.isMobile}
        themeBackgroundActive={false}
      >
        <section className="package-page">
          {deviceWidth ? (
            packages.map((packageInd: any) => {
              return (
                <div className="card-invite" style={{
                  height: 68
                }} onClick={() => {}}>
                  <div
                    className="status-line"
                    style={{
                      backgroundColor: ThemeService.getThemeData().component,
                    }}
                  ></div>

                  <div className="image-container">
                    <img
                    className="extraimg"
                      src={
                        packageInd.dumy_property
                          ? packageInd.dumy_property
                          : require("../../assets/imgs/logos/logoApp.png")
                      }
                    />
                  </div>

                  <div className="invite-info">
                    <p className="title">{t(packageInd.name)}</p>
                    <p className="description">{t(packageInd.description)}</p>
                  </div>

                  <div className="pricito">
                    ${packageInd.url}<span style={{fontSize: 9}}>(MXN)</span>
                  </div>
                </div>
              );
            })
          ) : (
            <ArsaTable template={tableData} />
          )}
        </section>
      </ArsaContainer>
    </>
  );
}
