import { useIonActionSheet, useIonModal } from "@ionic/react";
import { build, trash } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Subscription } from "rxjs";
import CardCrud from "../Catalogs/moduleComponents/CardCrud/CardCrud";
import "./arsa-card-registers.scss";
import CardCrudRegistersPresenter from "./presenter/CardCrudRegisters.presenter";

import {
  ArsaSpinner,
  ThemeService,
  useTheme,
  useDeviceDetect,
  UtilService,
  LocalStorageEncryptService,
  EventService,
  ArsaInput,
  ArsaFabButton,
  ArsaContainer,
  
} from "arsaLocalv4";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";

function CardCrudRegisters({}) {
  const [currentLineItem, setCurrentLineItem] = useState<any>();
  let suscription: Subscription;
  const [presentModal, dismiss] = useIonModal(CardCrud, {
    data: { ...currentLineItem },
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { type } = useParams();
  const [load, setLoad] = useState(true);
  const [catalog, setCatalog] = useState<any>(null);
  const [registers, setRegisters] = useState([]);
  const [registersTemp, setRegistersTemp] = useState([]);
  const [present] = useIonActionSheet();
  const device: any = useDeviceDetect();
  const [searchWord, setSearchWord] = useState({
    value: "",
    type: "text",
    required: false,
    hasError: false,
    maxLength: 200,
    placeholder: "list.search",
    extraComponent: (
      <div
        className="eye"
        onClick={() => {
        }}
      >
        <span className="material-icons outlined"> search </span>
      </div>
    ),
  });
  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, []);

  function init() {
    setCatalog(LocalStorageEncryptService.getFromLocalStorage("catalog"));
    loadInit();
  }

  async function loadInit(): Promise<void> {
    await CardCrudRegistersPresenter.load(
      setLoad,
      setRegisters,
      setRegistersTemp,
      type,
      t
    );
  }

  function findText() {
    setRegisters([...registersTemp]);
    setRegisters(
      UtilService.filterArrayByParam(
        registers,
        "name",
        searchWord.value.toLowerCase()
      )
    );
  }

  function back() {
    navigate(-1);
  }

  async function openCatalog(
    square: any = null,
    edit: boolean,
    register: any = null
  ) {
    setCurrentLineItem({
      square: square,
      edit: edit,
      catalogRegister: register,
    });
    presentModal({
      onWillDismiss: (ev: any) => {
        if (ev.detail.role === "confirm") {
        }
      },
      cssClass: !device.isMobile
        ? "modal-olam-channel-web"
        : "modal-olam-channel",
      mode: "ios",
    });
  }

  async function openSheet(register: any) {
    present({
      header: t("home.selection"),
      cssClass: `action-sheets-olam ${ThemeService.getThemeData().sheet}`,
      buttons: [
        {
          text: t("catalogs.edit"),
          icon: build,
          handler: () => {
            openCatalog(catalog, true, register);
          },
        },
        {
          text: t("catalogs.delete"),
          icon: trash,
          handler: () => {
            deleteRegister(register);
          },
        },
        {
          text: t("alerts.cancel"),
          role: "cancel",
          handler: () => {},
        },
      ],
    });
  }

  function deleteRegister(register: any) {
    CardCrudRegistersPresenter.delete(
      register,
      registersTemp,
      setRegistersTemp,
      setRegisters,
      t
    );
  }

  return (
    <>
      <ArsaHeader
        title={catalog?.text}
        applyPadding={true}
        isModal={false}
        backActive={true}
        menuActive={false}
        backAction={() => {
          navigate(-1);
        }}
      />
      <ArsaContainer
        customId={"main-content"}
        applyPadding={true}
        extraClass={"catalogs-module"}
      >
        <section>
          <div
            className="searching"
            style={{
              width: "calc(100% - 32px)",
            }}
          >
            <ArsaInput
              index={0}
              inputData={searchWord}
              extraClass="icon-s"
              onChange={(e) => {}}
            />
          </div>

          <section
            style={{
              paddingTop: "46px",
            }}
          >
            {load && <ArsaSpinner name={"crescent"} />}
            {!load &&
              registers.map((card: any, index: number) => {
                return (
                  <div
                    className="select-card"
                    onClick={() => {
                      openSheet(card);
                    }}
                    style={{
                      backgroundColor: theme.componentColor,
                    }}
                  >
                    <div className="ctn-im">
                      <img src={catalog.img} />
                    </div>

                    <div
                      className="ctn-txt"
                      style={{
                        color: theme.background,
                      }}
                    >
                      {card.name}
                      <p className="desc">{card.description}</p>
                    </div>
                  </div>
                );
              })}
          </section>
        </section>
      </ArsaContainer>
      <ArsaFabButton
        extraClass="fb-create"
        onClick={() => {
          openCatalog(catalog, false);
        }}
      >
        <span className="material-icons">add</span>
      </ArsaFabButton>
    </>
  );
}

export default CardCrudRegisters;
