//export const path = "http://localhost/marryme/"; //LOCAL
export const path = "https://www.minvite.mx/marryme/";//QA

export const marry = "marry/"; //LOCAL
//export const medic = "medic/medic.php/";//qa
export const emulate: boolean = false;
export const idEmpresa: number = 1; //This is ARSA
export const monitoringCatalog = 5;
export const customerType = 2;

export const environment = {
  genericQuerie: `${path}${marry}generic-querie`,
  petition: `${path}${marry}petition/`,
};

export const themeData = {
  blueStrong: {
    background: "#f2dabb",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#c79779",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#c79779",
    colorHeader: "#ffffff"
  },
  black: {
    background: "#f8e7cc",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#ccaa7b",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#ccaa7b",
    colorHeader: "#ffffff"
  },
  blue: {
    background: "#eead7a",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#945c1c",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#945c1c",
    colorHeader: "#ffffff"
  },
  purple: {
    background: "#ffe9c6",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#ffbe77",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#ffbe77",
    colorHeader: "#ffffff"
  },
  green: {
    background: "#f8e7cc",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#d6b188",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#d6b188",
    colorHeader: "#ffffff"
  },
  white: {
    background: "#f8e7cc",
    color: "#333",
    disabledColor: "#ffffff",
    enabledColor: "#ffffff",
    component: "#c79779",
    alert_class: "theme2",
    componentColor: "#fff",
    primaryColorTab: "primary8",
    primaryColorTabs: "primary2",
    primaryTabLetter: "light",
    sheet: "sheet2",
    backgroundHeader: "#c79779",
    colorHeader: "#ffffff"
  },
};

export const eventKeys = {
  loader: "loader",
  closeDrawer: "closeDrawer",
  menuGlobal: "menuGlobal",
  openNewPassModal: "openNewPassModal",
};

export const localStorageKeys = {
  menuOpen: "menuOpen",
};

export const SYSTEM_CONSTANTS = {
  mobileMinimum: 900,
  drawerWidth: 300,
  loginTemplate: "minviTe",
};

export const firebaseConfig = {
  apiKey: "AIzaSyB2iqGzmleN_QSnljcqNIO8LXyh1GRTsWU",
  authDomain: "medicapp-8e7c8.firebaseapp.com",
  projectId: "medicapp-8e7c8",
  storageBucket: "medicapp-8e7c8.appspot.com",
  messagingSenderId: "940933332226",
  appId: "1:940933332226:web:4ce92e363167aa1193161b",
  measurementId: "G-20VHLSGXDT",
};
