import React, { useEffect, useState } from "react";
import "./arsa-layout-tabs.scss";

import { ArsaTabs, Tabs, ThemeService } from "arsaLocalv4";
import { useNavigate } from "react-router-dom";
import { EventPage } from "../Events/Events";
import { ListInvites } from "../ListInvites/ListInvites";
const ArsaLayoutTabs: React.FC<any> = () => {
  const navigate = useNavigate();

  const [tabs, setTabs] = useState<Array<Tabs>>([
    {
      title: "home.invite",
      icon: "edit_note",
      active: false,
      component: <ListInvites />,
      url: "invites",
      reload: true,
    },
    {
      title: "home.events",
      icon: "list",
      active: false,
      component: <EventPage />,
      url: "about",
      reload: true,
    },
  ]);

  const [tab, setTabActive] = useState<Tabs>(tabs[0]);

  useEffect(() => {}, []);

  return (
    <>
      <section
        className="container-page ion-page"
        style={{
          backgroundColor: ThemeService.getThemeData().background,
        }}
      >
        {tab.component}
      </section>
      <ArsaTabs
        tabs={tabs}
        setTabActive={setTabActive}
        hasHome={true}
        homeClick={() => {
          navigate("/home");
        }}
      />
    </>
  );
};

export default ArsaLayoutTabs;
