import React, { useEffect, useState } from "react";
import { ArsaHeader } from "../../components/arsa-header/ArsaHeader";
import { useTranslation } from "react-i18next";
import {
  ArsaContainer,
  ArsaSpinner,
  ArsaSquare,
  ArsaTable,
  TableTemplate,
  UtilService,
  useDeviceDetect,
  useTheme,
} from "arsaLocalv4";
import "./events.scss";
import { EventsPresenter } from "./presenter/Events.presenter";
import { useNavigate } from "react-router-dom";

export function EventPage({}) {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [events, setEvents] = useState([]);
  const [tableData, setTableData] = useState<TableTemplate | null>(null);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const device: any = useDeviceDetect();
  
  useEffect(() => {
    init();
  }, []);

  async function init() {
    await EventsPresenter.getPublicEvents(setLoader, setEvents, setTableData, t, theme, navigate);
  }

  return (
    <>
      <ArsaHeader title={t("home.events")} applyPadding={true} />
      <ArsaContainer 
     customId={"main-content-crud-prcl"}
     applyPadding={true}
     noPadding={!device.isMobile}
     themeBackgroundActive={false}
      >
       {device?.isMobile ? <section className="content-events">
          {loader ? (
            <section className="loader-ctn">
              <ArsaSpinner height={40} name={"dots"} />
            </section>
          ) :  <section className="squares">
              {events.map((event: any) => {
                return (
                  <ArsaSquare
                    square={{
                      img: event.url_img,
                      text: <div className="text-event">
                        <p>{event.json_invite.principalForm[0].value}</p>
                        <p>{event.json_invite.principalForm[2].value}</p>
                        <p>{event.json_invite.principalForm[4].value}</p>
                      </div>,
                      onTap: ()=>{
                        navigate(`/invitation/${event.id}`);
                      }
                    }}
                    key={`id-square-${UtilService.getRandomText(9)}`}
                  />
                );
              })}
            </section>
          }
        </section> : <ArsaTable template={tableData} />}
      </ArsaContainer>
    </>
  );
}
